import { defineMessages } from 'react-intl';

export default defineMessages({
    documents: {
        id: 'document.component.document-component.Documents',
        defaultMessage: 'Documents'
    },
    uploadDocuments: {
        id: 'document.component.document-component.Upload Documents',
        defaultMessage: 'Upload Documents'
    },
    documentName: {
        id: 'document.component.document-component.File name',
        defaultMessage: 'File name'
    },
    searchDocuments: {
        id: 'document.component.document-component.Search documents',
        defaultMessage: 'Search Documents'
    },
    dateUploaded: {
        id: 'document.component.document-component.Date Modified',
        defaultMessage: 'Date Uploaded'
    },
    documentRemove: {
        id: 'document.component.document-component.Remove',
        defaultMessage: 'Remove'
    },
    dropaFilehere: {
        id: 'document.component.document-component.dropafilehere',
        defaultMessage: 'drag and drop a file here'
    },
    fileisUploaded: {
        id: 'document.component.document-component.fileisuploaded',
        defaultMessage: 'file is uploaded'
    },
    removeDocument: {
        id: 'document.component.document-component.Remove Document',
        defaultMessage: 'Remove Document'
    },
    confirmRemoveDocument: {
        id: 'document.component.document-component.Are you sure you want to remove',
        defaultMessage: 'Are you sure you want to remove'
    },
    documentRemoveConfirmYes: {
        id: 'document.component.document-component.Yes',
        defaultMessage: 'Yes'
    },
    documentRemoveConfirmNo: {
        id: 'document.component.document-component.No',
        defaultMessage: 'No'
    },
    noDocsAssociated: {
        id: 'document.component.document-component.There are no documents associated with this policy',
        defaultMessage: 'There are no documents associated with this policy'
    },
    addDocumentTitle: {
        id: 'gateway.directives.Add Document',
        defaultMessage: 'Add Document'
    },
    docType: {
        id: 'gateway.directives.gateway.Document Type',
        defaultMessage: 'Document Type'
    },
    addFiles: {
        id: 'gateway.directives.gateway.Add File',
        defaultMessage: 'Add File'
    },
    cancelDoc: {
        id: 'gateway.directives.Cancel',
        defaultMessage: 'Cancel'
    },
    pleaseSelectDocType:{
        id: 'gateway.directives.Please select a document type',
        defaultMessage: 'Please select a document type'
    },
    fileName:{
        id: 'gateway.directives.gateway-documents.Name',
        defaultMessage: 'Name'
    },
    uploadDocumentTooltip: {
        id: 'gateway.directives.To Upload SEPA documents, please visit Tango/Vconnect.toolTip',
        defaultMessage: 'Use the Upload Documents button to upload contract related documents. To Upload SEPA documents, please visit Tango/Connect.'
    },
    createDocuments: {
        id: "gateway.directives.gateway-documents.Create Documents",
        defaultMessage: "Create documents"
    },
    generateDocumentsCurrentPolicy: {
        id: "gateway.directives.gateway-documents.Generate Documents.Current Policy",
        defaultMessage: "Current contract document"
    },
    generateDocumentsCreationProgressMessage: {
        id: "gateway.directives.gateway-documents.Create Documents.Creation progress message",
        defaultMessage: "The document you requested will appear in the documents section in a few minutes. Refresh this page in order to obtain the document."
    },
    createDocumentsClose: {
        id: "gateway.directives.gateway-documents.Create Documents.close",
        defaultMessage: "Close"
    },
});
