import React, { useCallback } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import { LoadSaveService } from 'gw-capability-gateway-quoteandbind';
import { useTranslator } from '@jutro/locale';
import { JobUtil } from '@xengage/gw-portals-util-js';
import { MetadataContent } from '@jutro/uiconfig';
import appConfig from 'app-config';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { EdgeErrorParser } from 'pv-portals-util-js';
import metadata from './CopyContractComponent.metadata.json5';
import summaryMessage from '../../QuoteDetails/Summary/Summary.messages';
import messages from './CopyContractComponent.messages';
import styles from './CopyContractComponent.module.scss';

const { lobQuoteURL } = appConfig;

function CopyContractComponent(props) {
    const { authHeader } = useAuthentication();
    const history = useHistory();
    const { showAlert } = useModal();
    const { policyNumber, quoteNumber, duplicateButton, isPrimaryButton } = props;
    const translator = useTranslator();

    const copySubmissionPV = useCallback(async () => {
        try {
            const job = await LoadSaveService.copySubmissionPV(quoteNumber, policyNumber, authHeader);
            const postalCode = job.baseData.policyAddress.postalCode;
            const quoteID = job.quoteID;
            const productCode = job.baseData.productCode;

            const url = duplicateButton ? (lobQuoteURL[`QQ${productCode}`]) : lobQuoteURL[productCode];
            if (!_.isNil(url)) {
                const nextLocation = {
                    quoteentry: {
                        postalCode: postalCode,
                        quoteID: quoteID,
                        productCode: productCode
                    },
                    productCode
                };
                history.push(url, nextLocation);
            }
            else {
                JobUtil.openJobInXCenter(quoteID);
            }
        } catch (error) {
            const msg = EdgeErrorParser.getErrorMessage(error);
            showAlert({
                title: messages.copyContract,
                message: msg,
                status: 'error',
                icon: 'mi-error-outline'
            });
        }
    }, [showAlert, authHeader, duplicateButton, history, policyNumber, quoteNumber]
    );

    const overrideProps = {
        copyContractButtonId: {
            content: duplicateButton ? translator(summaryMessage.copySubmissionQuote) : translator(messages.copyContract)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            copySubmissionPV
        }
    };

    return <MetadataContent
        uiProps={metadata.componentContent}
        classNameMap={resolvers.resolveClassNameMap}
        overrideProps={overrideProps}
        {...resolvers} />;

}

CopyContractComponent.propTypes = {
    isPrimaryButton: PropTypes.bool,
    policyNumber: PropTypes.string,
    quoteNumber: PropTypes.string,
    duplicateButton: PropTypes.bool
};

CopyContractComponent.defaultProps = {
    isPrimaryButton: false,
    policyNumber: null,
    quoteNumber: null,
    duplicateButton: false
};

export default CopyContractComponent;
