import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { Icon, InputField, InlineLoader } from '@jutro/components';
import { ErrorLevel } from '@xengage/gw-portals-edge-validation-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WizardContext } from '@xengage/gw-portals-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { PolicyContext } from 'pv-capability-policyjob-react';
import styles from './WizardSidebar.module.scss';
import messages from './WizardSidebar.messages';

/**
 * @typedef {import('@xengage/gw-portals-edge-validation-js/errors').GenericUIIssue} GenericUIIssue
 */

/**
 * Renders an icon for steps with errors
 * @param {Array<GenericUIIssue>} errorsAndWarnings array
 * @returns {Object}
 */
function renderErrors(errorsAndWarnings = []) {
    const highestLevel = errorsAndWarnings
        .map((err) => err.level)
        .reduce((currentHighest, currentError) => {
            if (!currentHighest) {
                return currentError;
            }
            if (currentHighest === ErrorLevel.LEVEL_WARN) {
                // currentError can only Greater or Equal than currentHighest
                return currentError;
            }
            return currentHighest;
        }, null);
    switch (highestLevel) {
        case ErrorLevel.LEVEL_ERROR:
            return <Icon icon="mi-remove_circle_outline" className={styles.errorIssue} />;
        case ErrorLevel.LEVEL_WARN:
            return <Icon icon="mi-warning" className={styles.warningIssue} />;
        default:
            return null;
    }
}

function jumpRenderModal(index, jumpTo, steps, step, wizardContext, customMessageModal, canJumpPage, setOnJump) {
    const modalMessages = {
        title: !_.isEmpty(customMessageModal)
            ? customMessageModal.title
            : commonMessages.wantToJump,
        message: !_.isEmpty(customMessageModal)
            ? customMessageModal.message
            : commonMessages.wantToJumpMessage,
        messageProps: {
            ok: commonMessages.yes,
            close: commonMessages.close
        }
    };
    const { wizardData, wizardSnapshot, onPageJump } = wizardContext;

    setOnJump(true); // Event trigger for wizard pages
    if (canJumpPage) {
        onPageJump({
            wizardData,
            wizardSnapshot,
            modalMessages,
            index
        });
    }
}

function isStepDisabled(steps, step, currentStepIndex) {
    if (steps[currentStepIndex]?.stepProps?.disableOtherSteps) {
        return steps[currentStepIndex].id !== step.id;
    }
    return !step.visited;
}

function renderSteps(
    translator,
    steps,
    currentStepIndex,
    stepsWithErrors,
    jumpTo,
    wizardContext,
    customMessageModal,
    canJumpPage,
    setOnJump
) {
    return steps.map((step, index) => {
        const liClassName = classNames(styles.step, {
            [styles.active]: index === currentStepIndex,
            [styles.notVisited]: isStepDisabled(steps, step, currentStepIndex)
        });
        return (
            <li className={liClassName} key={step.id}>
                <button
                    className={styles.navigationButton}
                    onClick={() =>
                        jumpRenderModal(
                            index,
                            jumpTo,
                            steps,
                            step,
                            wizardContext,
                            customMessageModal,
                            canJumpPage,
                            setOnJump
                        )
                    }
                    disabled={isStepDisabled(steps, step, currentStepIndex)}
                    type="button"
                >
                    {translator(step.title)}
                    {renderErrors(stepsWithErrors[step.id])}
                </button>
            </li>
        );
    });
}

const OwnReference = ({ wizardData, translator, setChangesToBeSentFromSidebar }) => {
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { authHeader } = useAuthentication();

    const ownReferencePath = 'baseData.ownReference_PV.value';
    const [ownRefValue, setOwnRef] = useState(_.get(wizardData, ownReferencePath, ''));
    const [savingRef, setSavingRef] = useState(false);
    const [ownRefLabel, setOwnRefLabel] = useState(
        _.get(wizardData, ownReferencePath) ? `: ${_.get(wizardData, ownReferencePath)}` : ''
    );

    const saveOwnReference = async () => {
        const storedValue = _.get(wizardData, ownReferencePath, '');
        if (storedValue === ownRefValue) {
            return;
        }

        const { sessionUUID, quoteID } = wizardData;

        try {
            setSavingRef(true);
            await LoadSaveService.saveOwnReference(
                {
                    sessionUUID: sessionUUID.value,
                    quoteID: quoteID.value,
                    ownReference_PV: ownRefValue
                },
                authHeader
            );
            _.set(wizardData, ownReferencePath, ownRefValue);
            setOwnRefLabel(ownRefValue ? `: ${ownRefValue}` : '');
        } catch(err) {
            // Error saving new value, restore previous value
            setOwnRef(storedValue);
        } finally {
            setTimeout(() => {
                // Delay update to avoid moving forward accidentally when click on navigation button
                setChangesToBeSentFromSidebar(false);
            }, 500);
            setSavingRef(false);
        }
    };

    const onValueChange = (value) => {
        const storedValue = _.get(wizardData, ownReferencePath);
        if (storedValue !== value) {
            setChangesToBeSentFromSidebar(true);
        } else {
            setChangesToBeSentFromSidebar(false);
        }
        setOwnRef(value);
    };

    const renderInput = () => {
        const isBound = _.get(wizardData, 'baseData.baseData.periodStatus.value.code') === 'Bound';
        const isIssued = _.get(wizardData, 'baseData.isIssued_PV.value');

        return (
            <>
                {(isBound || isIssued) ?
                    <>
                        <span>{`${translator(messages.ownReference)}${ownRefLabel}`}</span>
                    </>
                    :
                    <InputField
                        id='ownReference'
                        label={`${translator(messages.ownReference)}${ownRefLabel}`}
                        path={ownReferencePath}
                        onValueChange={onValueChange}
                        onBlur={saveOwnReference}
                        value={ownRefValue}
                    />
                }
            </>
        );
    };

    return (
        <div className={styles.ownReferenceContainer}>
            {savingRef ?
                <>
                    <span>{`${translator(messages.ownReference)}${ownRefLabel}`}</span>
                    <InlineLoader loading={savingRef} loadingMessage={translator(messages.saving)} className="gw-inline-loader" />
                </>
                :
                <>
                    {renderInput()}
                </>
            }
        </div>
    );
};

const transformPath = (path) => path.reduce((prev, curr) => `${prev}${typeof curr === 'number' ? '[' : '.'}${curr}${typeof curr === 'number' ? ']' : ''}`);

function findInvalidProps(obj, path = [], retVal = {}) {
    for (const key in obj) {
        // check only non computed properties
        if (typeof obj[key] === 'object' && !key.startsWith('_') && !['value', 'aspects', 'baseVMNode'].includes(key)) {
            path.push(key);
            const { subtreeValid, valid } = obj[key].aspects || {};
            if (valid === false) {
                console.log('\x1b[101;1m%s\x1b[0m', `${key} is not valid`);
                retVal[transformPath(path)] = obj[key].aspects.validationMessages;
            }
            if (subtreeValid === false) {
                if (Object.getPrototypeOf(obj[key]).hasOwnProperty('children')) {
                    obj[key].children.forEach((child, idx) => {
                        path.push(idx);
                        console.log(`subtree of ${key}[${idx}] is invalid. Current path is ${transformPath(path)}`);
                        findInvalidProps(child, path, retVal);
                        path.pop();
                    });
                } else {
                    console.log(`subtree of ${key} is invalid. Current path is ${transformPath(path)}`);
                    findInvalidProps(obj[key], path, retVal);
                }
            }
            path.pop();
        }
    }
    return retVal;
}

function WizardSidebar(props) {
    const wizardContext = useContext(WizardContext);
    const policyContext = useContext(PolicyContext);
    const translator = useTranslator();
    const {
        steps, currentStepIndex, jumpTo, stepsWithErrors, wizardTitle, wizardData, currentStep
    } = wizardContext;
    const { customMessageModal, setChangesToBeSentFromSidebar } = props;
    const { showOwnReference = false } = currentStep.stepProps;
    const { canJumpPage, setOnJump } = policyContext;
    const [ rerender, setRerender] = useState(0);
    const showInvalidPropsModal = !!localStorage.getItem('showInvalidPropsModal');
    const invalidProps = showInvalidPropsModal ? findInvalidProps(wizardData) : [];
    const handleMinimize = (event) => event.target.parentElement.classList.toggle(styles.minimizeModal);
    const handleClose = () => {localStorage.removeItem('showInvalidPropsModal'); setRerender(rerender + 1);};

    return (
        <div className={styles.wizardSidebarWrapper}>
            <h3 className={styles.sideBarTitle}>{translator(wizardTitle)}</h3>
            <div role="navigation" aria-label={translator(wizardTitle)}>
                <ul className={styles.stepsNav}>
                    {renderSteps(
                        translator,
                        steps,
                        currentStepIndex,
                        stepsWithErrors,
                        jumpTo,
                        wizardContext,
                        customMessageModal,
                        canJumpPage,
                        setOnJump
                    )}
                </ul>
            </div>
            {showOwnReference && <OwnReference
                wizardData={wizardData}
                translator={translator}
                setChangesToBeSentFromSidebar={setChangesToBeSentFromSidebar}
            />}
            {showInvalidPropsModal ? <div className={styles.invalidPropsModal}>
                <div className={[styles.modalIcon, styles.removeIcon].join(' ')}
                    role='button'
                    tabIndex={0}
                    onKeyDown={handleClose}
                    onClick={handleClose} />
                {Object.keys(invalidProps).length !== 0 &&
                <>
                    <div className={[styles.modalIcon, styles.invalidPropsCounter].join(' ')}>{Object.keys(invalidProps).length}</div>
                    <div className={[styles.modalIcon, styles.minimizeModalIcon].join(' ')}
                        role='button'
                        tabIndex={0}
                        onKeyDown={handleMinimize}
                        onClick={handleMinimize} />
                </>
                }
                <h2>Invalid properties in ViewModel</h2>
                <div>Submission status: <b>{wizardData?.value?.status || wizardData?.baseData?.value?.periodStatus}</b></div>
                <div className={styles.invalidPropsContainer}>
                    <pre>
                        {Object.keys(invalidProps).length !== 0 ? JSON.stringify(invalidProps, null, 2) : 'No invalid props 🙌'}
                    </pre>
                </div>
            </div> : null}
        </div>
    );
}

WizardSidebar.propTypes = {
    customMessageModal: PropTypes.shape({}),
    setChangesToBeSentFromSidebar: PropTypes.func.isRequired
};
WizardSidebar.defaultProps = {
    customMessageModal: {}
};
export default WizardSidebar;
