import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import { MetadataContent } from '@jutro/uiconfig';
import { UserService } from 'gw-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import metadata from './NewQuoteButton.metadata.json5';
import './NewQuoteButton.messages';

const NewQuoteButton = () => {
    const [permissionCheck, updatePermissionCheck] = useState(false);
    const [createSubmissionPerm, updateCreateSubmissionPerm] = useState(false);
    const auth = useAuthentication();
    const location = useLocation();
    const history = useHistory();

    const checkPermissions = useCallback(async () => {
        if (_.isEmpty(auth.authHeader) || permissionCheck) {
            return;
        }
        updatePermissionCheck(true);

        const accountPermDTO = {
            permEntityType: 'Account',
            permEntityID: null,
            permission: 'create',
            isCheckPermEntity: false
        };

        const submissionPermDTO = {
            permission: 'createsubmission'
        };

        const createSubmissionPermission = UserService.hasUserSystemPermission(
            submissionPermDTO,
            auth.authHeader
        );

        const createAccountPermission = UserService.hasUserPermission(
            accountPermDTO,
            auth.authHeader
        );

        const [canCreateSubmission, canCreateAccount] = await Promise.all([createSubmissionPermission, createAccountPermission]);
        updateCreateSubmissionPerm(canCreateSubmission && canCreateAccount);
    }, [auth.authHeader, permissionCheck]);

    useEffect(() => {
        checkPermissions();
    }, [checkPermissions]);


    const overrideProps = {
        newQuoteButton: {
            visible: createSubmissionPerm
        },
        accountOptionsContainer: {
            visible: /\/accounts\/.*/.test(location.pathname)
        },
        otherOptionsContainer: {
            visible: !/\/accounts\/.*/.test(location.pathname)
        }
    };

    const onCreateQuote = ({ isExistingAccount, isTariff }) => {
        if (isExistingAccount) {
            const accountNumber = location.pathname.split('/')[2];
            const nextLocation = {
                quickQuote: isTariff
            };
            history.push(`/new-quote/${accountNumber}`, nextLocation);
        } else {
            const nextLocation = {
                quoteType: isTariff ? 'pvQuickQuote' : 'pvNewBusiness'
            };
            window.sessionStorage.setItem('createNewQuoteType_PV19883', isTariff ? 'pvQuickQuote' : 'pvNewBusiness')
            history.push('/new-quote-account-search', nextLocation);
        }
    }

    const resolvers = {
        resolveCallbackMap: {
            onCreateQuote
        }
    }

    return <MetadataContent
        uiProps={metadata.componentContent}
        overrideProps={overrideProps}
        {...resolvers} />
};


export default NewQuoteButton;
