import React, { useCallback } from 'react';
import { MetadataContent } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { EdgeErrorParser } from 'pv-portals-util-js';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import metadata from './PrintDocumentComponent.metadata.json5';
import messages from './PrintDocumentComponent.messages';

function PrintDocumentComponent(props) {
    const { authHeader } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const translator = useTranslator();

    const { showAlert } = useModal();

    const {
        quoteID,
        sessionUUID,
        branchName,
        toProducer,
        buttonLabel,
        className,
        onBeforePrinting,
        onAfterPrinting,
        disabled,
        buttonType,
        icon
    } = props;

    const openDocumenPV = useCallback((sessionID, publicID, source_PV) => {
        const downloadUrl = GatewayDocumentService.downloadDocument(publicID, sessionID, source_PV);
        window.open(downloadUrl);
    }, []);

    const printQuoteDocumentPV = useCallback(async () => {

        onBeforePrinting();

        const emailQuoteDTO = {
            'quoteID': quoteID,
            'sessionID': sessionUUID,
            'branchCode_PV': branchName,
            'toProducer_PV': toProducer
        };

        try {
            const document = await LoadSaveService.generateQuoteDocumentPV(emailQuoteDTO, authHeader);

            showAlert({
                title: messages.creationInProgress,
                message: messages.documentWarning,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: platformMessages.ok
            });
            setTimeout(() => {
                openDocumenPV(document.sessionID, document.publicID, document.source_PV);
            }, 2000);

            onAfterPrinting();

        } catch (error) {
            const msg = EdgeErrorParser.getErrorMessage(error);
            showAlert({
                title: messages.errorTittle,
                message: `${translator(messages.error)}\n${msg}`,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: platformMessages.ok
            });
        }
    }, [quoteID, translator, sessionUUID, branchName, toProducer, LoadSaveService, authHeader, showAlert, onBeforePrinting, onAfterPrinting, openDocumenPV]);

    const overrideProps = {
        printDocumentButton: {
            visible: !icon,
            disabled: disabled,
            content: buttonLabel,
            className: className,
            type: buttonType
        },
        tooltip: {
            visible: icon
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            printQuoteDocumentPV
        }
    };

    return (
        <MetadataContent
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            {...resolvers}
        />
    );
}

PrintDocumentComponent.propTypes = {
    quoteID: PropTypes.string.isRequired,
    sessionUUID: PropTypes.string.isRequired,
    branchName: PropTypes.string,
    toProducer: PropTypes.bool,
    onBeforePrinting: PropTypes.func,
    onAfterPrinting: PropTypes.func,
    buttonLabel: PropTypes.element,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    buttonType: PropTypes.string,
    icon: PropTypes.bool
};

PrintDocumentComponent.defaultProps = {
    branchName: undefined,
    toProducer: true,
    onBeforePrinting: () => { },
    onAfterPrinting: () => { },
    buttonLabel: undefined,
    className: '',
    disabled: false,
    buttonType: 'filled',
    icon: false
};

export default PrintDocumentComponent;
