import { authTokenHandler, HttpRequestBuilder, jsonOptions } from "@jutro/transport";

/**
 * @memberof module:gw-microapp-util-js
 * @function getRestService
 * 
 * @param {string} base The base url of the request 
 * @returns {HttpRequest} An authenticated HTTPRequest setup with the baseURL provided
 */

export const getRestService = ({
    baseUrl
}) => {
    const request = new HttpRequestBuilder(baseUrl)
        .addOptions(jsonOptions)
        .addHandler(authTokenHandler)
    
    const requestService = request.build();
    return requestService;
};