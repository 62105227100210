import { getConfigValue } from "@jutro/config"
import { error as errorLogger} from "@jutro/logger"
import { publish, JUTRO_TOPICS } from "@jutro/events";

import { getRestService } from "./RestServiceCreator";

/**
 * 
 * A default handler for errors that occur during the operation of the microapp flows
 * 
 * @memberof module:gw-microapp-util-js
 * 
 * @function defaultHandleError
 * 
 * @param {Object} error The error object that had occured 
 * @param {Object} debugInfo Debug information that will be used for appropriate logging
 */
export const defaultHandleError = (error, debugInfo) => {
    const { endpoint, method } = debugInfo;
    let errorLogObject;

    if (typeof error === 'string') {
        errorLogObject = {
            ...debugInfo,
            message: error,
            type: `error:microapp.API`,
            title: 'Failed to complete request',
        };
        errorLogger(errorLogObject);
        publish(JUTRO_TOPICS.FETCH_ERROR, errorLogObject);
    
        throw new Error(error);
    } else {
        let parsedError;
        try {
            parsedError = JSON.parse(error.message);
        } catch {
            parsedError = { title: error.message, ...error };
        }

        const customizedErrorMessage = `API failure on ${endpoint}:${method} with: ${parsedError.title}`;

        errorLogObject = {
            ...error,
            ...debugInfo,
            message: customizedErrorMessage,
            type: `error:microapp.${parsedError.type}`,
            title: 'Failed to complete request',
            errorCode: error.status
        };

        errorLogger(errorLogObject);
        publish(JUTRO_TOPICS.FETCH_ERROR, errorLogObject);

        const errorWithCode = new Error(customizedErrorMessage);
        errorWithCode.code = error.status;
        errorWithCode.type = parsedError.type;

        throw errorWithCode;
        
    }
}

/**
 * A default passthrough handler function that returns the passed in response
 * 
 * @memberof module:gw-microapp-util-js
 * @param {Object} response 
 * @returns {Object} The response passed in
 */
const defaultHandleSuccess = (response) => response;

/**
 * @typedef {Object} MicroAppServiceAPIPayload
 * @memberof module:gw-microapp-util-js
 * @property {String} endpoint endpoint to call out to
 * @property {String} method REST method 
 * @property {Object} overrideHeaders optional override headers to apply to the REST callout
 * @property {Object} payload the payload to attach to the method
 */

/**
 * Makes a callout to the MicroApp Admin service layer with the provided API information
 * 
 * @function fetchMicroAppServiceApiRequest
 * @memberof module:gw-microapp-util-js
 * @param {MicroAppServiceAPIPayload} apiRequest The request object required for the API callout
 * @returns {Object} the response object from the called api
 */
export const fetchMicroAppServiceApiRequest = ({
    endpoint,
    method,
    overrideHeaders = {},
    payload,
}) => {
    const microappAdminServiceBaseUrl = getConfigValue('MICROAPP_ADMIN_SERVICE_URL')
    const quoteFlowTenant =  getConfigValue('POLICY_TRANSACTION_TENANT');
    const grn = getConfigValue('GRN');
    const restService = getRestService({
        baseUrl: microappAdminServiceBaseUrl
    });

    const headers = {
        ...overrideHeaders,
        'gw-tenant': quoteFlowTenant,
        'gw-request-grn': grn        
    };

    return fetchApiRequest(
        restService,
        endpoint,
        method,
        payload,
        headers
    )
}

/**
 * This makes a REST based callout to the provided endpoint and returns the response object from the underlying api
 * 
 * @function fetchApiRequest
 * @memberof module:gw-microapp-util-js
 * @param {Object} restService see for more information
 * @param {String} endpoint endpoint to call out to
 * @param {String} method REST method 
 * @param {Object} payload the payload to attach to the method
 * @param {Object} overrideHeaders optional override headers to apply to the REST callout
 * @param {Function} handleError error handler function
 * @param {Function} handleSuccess  success handler function
 * 
 * @returns {Object} the response from the underlying api call
 */
export const fetchApiRequest = async (
    restService,
    endpoint,
    method,
    payload,
    overrideHeaders,
    handleError = defaultHandleError,
    handleSuccess = defaultHandleSuccess
) => {
    const debugInfo = { endpoint, method }
    const response = await restService[method.toLowerCase()](
        endpoint,
        payload,
        {
            headers: {
                ...overrideHeaders
            }
        }
    )
        .then((res) => handleSuccess(res, debugInfo))
        .catch((error) => {
            handleError(error, debugInfo)
        });
    return response;
}