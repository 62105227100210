import React, { useState, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import {
    Button,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { withValidation, validationPropTypes, validationDefaultProps } from '@xengage/gw-portals-validation-react';
import metadata from './EditAccountDetailsPopUp.metadata.json5';
import styles from './EditAccountDetailsPopUp.module.scss';

const EditAccountDetailsPopUp = (props) => {
    const {
        accountData,
        title,
        actionBtnLabel,
        cancelBtnLabel,
        isOpen,
        onResolve,
        onReject,
        isComponentValid,
        setComponentValidation,
        viewModelService,
        authHeader
    } = props;
    const [accountDetailsVM, setAccountDetailsVM] = useState(null);

    const createVM = useCallback((model) => {
        return viewModelService.create(
            model,
            'pc',
            'edge.capabilities.policycommon.accountcontact.dto.AccountContactDTO',
            { IsAgent: true, EditAccountDetails: true, ValidateGender: true }
        );
    }, [viewModelService]);

    const initialAccountData = useCallback((model) => {
        if (
            !_.isEmpty(accountData)
            && !_.isEmpty(accountData.accountHolder)
            && !_.isEmpty(accountData.accountHolder.primaryAddress)
        ) {
            _.set(model, 'accountHolder.value', accountData.accountHolder);
            _.set(model, 'homeNumber.value', accountData.accountHolder.homeNumber);
            _.set(model, 'workNumber.value', accountData.accountHolder.workNumber);
            _.set(model, 'cellNumber.value', accountData.accountHolder.cellNumber);
            _.set(
                model,
                'primaryAddress.value',
                accountData.accountHolder.primaryAddress
            );
            _.set(model, 'emailAddress1.value', accountData.accountHolder.emailAddress1);
            _.set(
                model,
                'primaryPhoneType.value',
                accountData.accountHolder.primaryPhoneType
            );
        }
        return model;
    }, [accountData]);

    const writeValue = useCallback((value, path) => {
        if (accountDetailsVM) {
            if (path.includes("shareEmailAddress_PV") && value === true) {
                accountDetailsVM.value.accountHolder.emailAddress1 = null;
                _.set(accountDetailsVM, 'emailAddress1.value', accountData.accountHolder.emailAddress1);
            }
            const model = viewModelService.clone(accountDetailsVM);

            _.set(model, path, value);
            setAccountDetailsVM(model);
        }
    }, [accountData.accountHolder.emailAddress1, accountDetailsVM, viewModelService]);

    const handleSave = useCallback(() => {
        const accountHolder = accountDetailsVM.accountHolder.value;
        if (accountDetailsVM.subtype.value === 'Person') {
            accountHolder.firstName = _.get(accountDetailsVM, 'firstName.value');
            accountHolder.lastName = _.get(accountDetailsVM, 'lastName.value');
            accountHolder.dateOfBirth = _.get(accountDetailsVM, 'dateOfBirth.value');
            accountHolder.gender = _.get(accountDetailsVM, 'gender.value.code');
            accountHolder.primaryPhoneType = _.get(accountDetailsVM, 'primaryPhoneType.value.code', 'mobile');
        } else {
            accountHolder.primaryPhoneType = _.get(accountDetailsVM, 'primaryPhoneType.value.code', 'work');
        }
        accountHolder.emailAddress1 = _.get(accountDetailsVM, 'shareEmailAddress_PV.value') === false ? _.get(accountDetailsVM, 'emailAddress1.value') : null;
        accountHolder.shareEmailAddress_PV = _.get(accountDetailsVM, 'shareEmailAddress_PV.value', null);
        accountHolder.enterpriseNumber_PV = _.get(accountDetailsVM, 'enterpriseNumber_PV.value');
        accountHolder.emailUsageCommercial_PV = _.get(accountDetailsVM, 'emailUsageCommercial_PV.value');
        accountHolder.workNumber = _.get(accountDetailsVM, 'workNumber.value', null);
        accountHolder.homeNumber = _.get(accountDetailsVM, 'homeNumber.value', null);
        accountHolder.cellNumber = _.get(accountDetailsVM, 'cellNumber.value', null);

        const requestObject = {
            accountHolder,
            accountNumber: accountData.accountNumber
        };

        if (isComponentValid) {
            onResolve(requestObject);
        }
    }, [onResolve, accountData, accountDetailsVM, isComponentValid]);

    const isEnterpriseNumberAvailable = useMemo(() => {
        if (accountData.accountHolder.enterpriseNumber_PV === undefined || accountData.accountHolder.enterpriseNumber_PV === null
        || accountData.accountHolder.enterpriseNumber_PV === '' || accountData.accountHolder.enterpriseNumber_PV === '0000000000') {
            return false;
        }
        return true;
    }, [accountData.accountHolder]);

    useEffect(() => {
        const model = createVM(accountData.accountHolder);
        const updatedModel = initialAccountData(model);
        setAccountDetailsVM(updatedModel);
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountData]);

    const overrideProps = {
        '@field': {
            showRequired: true,
            showErrors: true
        },
        enterpriseNumberPerson: {
            visible: accountDetailsVM?.deductVatForVehicle_PV.value === true,
            readOnly: isEnterpriseNumberAvailable
        },
        enterpriseNumberCompany: {
            readOnly: isEnterpriseNumberAvailable
        },
        homeNumber: {
            visible: _.get(accountDetailsVM, 'subtype.value') === 'Company',
            readOnly: _.get(accountDetailsVM, 'subtype.value') === 'Company'
        },
        workNumber:{
            visible: _.get(accountDetailsVM, 'subtype.value') === 'Company'
        },
        personalContainer: {
            visible: _.get(accountDetailsVM, 'subtype.value') === 'Person'
        },
        companyContainer: {
            visible: _.get(accountDetailsVM, 'subtype.value') === 'Company'
        },
        email: {
            disabled: _.get(accountDetailsVM, 'shareEmailAddress_PV.value') === true,
            content: _.get(accountDetailsVM, 'shareEmailAddress_PV.value') === true ? null : _.get(accountData, 'accountHolder.emailAddress1')
        },
        // eslint-disable-next-line camelcase
        shareEmailAddress_PV: {
            visible: !accountData.accountHolder.emailAddress1
        },
        addressComponent:{
            authCode: authHeader
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onValidate: setComponentValidation
        }
    };

    return (
        <ModalNext isOpen={isOpen} className={styles.editContactDetailsModal}>
            <ModalHeader
                title={title}
                onClose={onReject}
            />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={accountDetailsVM}
                    overrideProps={overrideProps}
                    classNameMap={styles}
                    onValueChange={writeValue}
                    onValidationChange={setComponentValidation}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">
                    {cancelBtnLabel}
                </Button>
                <Button onClick={handleSave} >
                    {actionBtnLabel}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
};

EditAccountDetailsPopUp.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func.isRequired,
        clone: PropTypes.func.isRequired,
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
    accountData: PropTypes.shape({
        accountNumber: PropTypes.string.isRequired,
        accountHolder: PropTypes.shape({
            primaryAddress: PropTypes.shape({}).isRequired,
            emailAddress1: PropTypes.shape({}).isRequired,
            subtype: PropTypes.shape({}).isRequired,
            cellNumber: PropTypes.string.isRequired,
            homeNumber: PropTypes.string.isRequired,
            workNumber: PropTypes.string.isRequired,
            primaryPhoneType: PropTypes.string
        }).isRequired
    }).isRequired,
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.shape({}).isRequired,
    cancelBtnLabel: PropTypes.shape({}).isRequired,
    onReject: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onResolve: PropTypes.func.isRequired,
    ...validationPropTypes,
    ...validationDefaultProps
};

export default withValidation(EditAccountDetailsPopUp);
