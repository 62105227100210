import React, { useContext, useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ScrollToError } from '@jutro/wizard-next';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { BusinessConstant as BC } from 'pv-portals-util-js';
import VinAndLicenseplate from '../../components/VinAndLicenseplate/VinAndLicenseplate';
import metadata from './VehiclesQuickPage.metadata.json5';
import messages from '../VehiclesPage/VehiclesPage.messages';

function VehiclesQuickPage(props) {
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const breakpoint = useContext(BreakpointTrackerContext);
    const { wizardData: submissionVM, updateWizardData} = props;
    const {
        onValidate,
        initialValidation,
        registerComponentValidation
    } = useValidation('VehiclesQuickPage');
  	const defaultActiveTab = 'vehicle0';
    const [activeTab, setActiveTab] = useState(defaultActiveTab);
    const [showErrors, setShowErrors] = useState(false);
    const [errorTimestamp, setErrorTimestamp] = useState(0);
    const { authHeader } = useAuthentication();

    const [productCode] = useState(_.get(submissionVM, 'baseData.productCode.value'));
    const [isCommercial] = useState(productCode === BC.PCV_PROD_CODE);
    const [lobVmPath] = useState(isCommercial ? BC.PCV_LOB_CODE : BC.PPV_LOB_CODE);

    const [vehicleListVm] = useState(_.get(submissionVM, `lobData.${lobVmPath}.coverables.vehicles.children`, []));
    const activeTabIdx = activeTab.charAt(activeTab.length - 1);
    const [selectedVehicleVm] = useState(_.get(submissionVM, `lobData.${lobVmPath}.coverables.vehicles.children[${activeTabIdx}]`));

    useEffect(() => {
        // Change context
        const context = {
            IsAgent: true,
            preQuote: true,
            AdditionalQuestions: true,
            DriverDetails: false
        };
        const vm = viewModelService.changeContext(submissionVM, context);

        updateWizardData(vm);
        // Only on first render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTabHeader = useCallback((vehicleVm) => {
        const typeName = _.get(vehicleVm, 'type.value.name');
        const typeMsg = typeName ? translator({ id: typeName }) : translator(messages.vehicle);
        return (
            <React.Fragment>
                <span id={typeMsg}>{typeMsg}</span>
            </React.Fragment>
        );
    }, [translator]);

    const writeValue = useCallback(
        (value, path) => {
            if (value === '') {
                value = undefined;
            }
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const onTabChange = useCallback((selectedTabID) => {
        setActiveTab(selectedTabID);
    }, []);

    useEffect(() => {
        registerComponentValidation(() => {
            return selectedVehicleVm &&
                selectedVehicleVm.aspects.valid &&
                selectedVehicleVm.aspects.subtreeValid;

        });
    }, [selectedVehicleVm, registerComponentValidation]);

    const onNext = useCallback(async () => {
        setShowErrors(true);

        // 1. do not switch to 1st invalid TAB, but stay on current invalid
        if (!selectedVehicleVm?.aspects.valid || !selectedVehicleVm?.aspects.subtreeValid) {
            setErrorTimestamp(Date.now());
            return false;
        }

        // 2. check other tabs now ...
        const invalidVehicleIdx = vehicleListVm.findIndex((vehicleVm) => !vehicleVm.aspects.valid || !vehicleVm.aspects.subtreeValid);
        if (invalidVehicleIdx !== -1) {
            setActiveTab(`vehicle${invalidVehicleIdx}`);
            setErrorTimestamp(Date.now());
            return false;
        }

        submissionVM.value = await LoadSaveService.updateDraftSubmission(submissionVM.value, authHeader);
        submissionVM.value = await LoadSaveService.getPremium_PV(submissionVM.value, authHeader);

        return submissionVM;
    }, [selectedVehicleVm, submissionVM, LoadSaveService, authHeader, vehicleListVm]);

    const generateOverrides = useCallback(() => {
        const overrides = vehicleListVm.map((vehicleVm, index) => (
            {
                [`vehicle${index}`]: {
                    heading: getTabHeader(vehicleVm)
                }
            })
        );
        return Object.assign({}, ...overrides);
    }, [vehicleListVm, getTabHeader]);


    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        vehicleList: {
          	defaultActiveTab: defaultActiveTab,
            activeTab: activeTab,
            path: `lobData.${lobVmPath}.coverables.vehicles.children`
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveCallbackMap: {
            onTabChange,
            onValidate
        },
        resolveComponentMap: {
            VinAndLicenseplate,
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                showErrors={showErrors}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
            <ScrollToError counter={errorTimestamp} timeout={200} />
        </WizardPage>
    );
}

VehiclesQuickPage.propTypes = wizardProps;
export default VehiclesQuickPage;
