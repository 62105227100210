import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { MetadataContent } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { withModalContext } from '@jutro/components';
import { withDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import DocumentComponent from '../../components/DocumentsComponent/DocumentsComponent';
import documentMessages from '../../Document.messages';
import metadata from './DocumentsPage.metadata.json5';
import messages from './DocumentsPage.messages';

const DOCUMENTS_KEY = 'Documents';

class DocumentsPage extends Component {
    static propTypes = {
        DocumentPageService: PropTypes.shape({
            generateUploadToken: PropTypes.func,
            uploadDocument: PropTypes.func,
            getDocuments: PropTypes.func,
            getNonInvoiceDocuments: PropTypes.func,
            removeDocument: PropTypes.func,
            downloadDocument: PropTypes.func,
            fromPolicyDetails: PropTypes.bool
        }).isRequired,
        authHeader: PropTypes.shape({
            Authorization: PropTypes.string
        }).isRequired,
        jobNumber: PropTypes.string,
        accountNumber: PropTypes.string,
        policyNumber: PropTypes.string,
        setDocumentCount: PropTypes.func,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        additionalDocMetadataPropsPV: PropTypes.shape({})
    };

    static defaultProps = {
        jobNumber: undefined,
        policyNumber: undefined,
        accountNumber: undefined,
        setDocumentCount: undefined,
        additionalDocMetadataPropsPV: {}
    };

    state = {
        documentsData: [],
        isLoading: true,
        docType: '',
        showDocDetails: false
    };

    componentDidMount() {
        this.getDocuments();
    }

    getCorrectNumber = () => {
        const { jobNumber, accountNumber, policyNumber } = this.props;
        return jobNumber || accountNumber || policyNumber;
    }

    getDocuments = async () => {
        const {
            authHeader, setDocumentCount, DocumentPageService, modalContext
        } = this.props;

        this.setState({ isLoading: true });
        await DocumentPageService.getDocuments(this.getCorrectNumber(), authHeader)
            .then((availableDocumentsData) => {
                this.setState({
                    documentsData: availableDocumentsData
                });
                if (setDocumentCount) {
                    setDocumentCount(availableDocumentsData.length, DOCUMENTS_KEY);
                }
                DocumentPageService.fromPolicyDetails && this.getNonInvoiceDocuments();

            }).catch(() => {
                modalContext.showAlert({
                    title: messages.modalError,
                    message: messages.errorLoadingDocument,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            }).finally(() => {
                this.setState({ isLoading: false });
            });
    };

    getNonInvoiceDocuments = async () => {
        const {
            authHeader, DocumentPageService, modalContext
        } = this.props;

        this.setState({ isLoading: true });
        await DocumentPageService.getNonInvoiceDocuments(this.getCorrectNumber(), authHeader)
            .then((availableDocumentsData) => {
                this.setState((prevState) => ({
                    documentsData: [...prevState.documentsData, ...availableDocumentsData]
                }));
            }).catch(() => {
                modalContext.showAlert({
                    title: messages.modalError,
                    message: messages.errorLoadingDocument,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
            }).finally(() => {
                this.setState({ isLoading: false });
            });
    };

    generateUploadToken = () => {
        const { DocumentPageService, authHeader, modalContext } = this.props;
        return DocumentPageService.generateUploadToken(authHeader).catch(() => {
            return modalContext.showAlert({
                title: commonMessages.errorUploadTitle,
                message: commonMessages.errorGenerateUploadToken,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
        });
    };

    uploadDocument = async (file) => {
        const {
            authHeader,
            jobNumber,
            accountNumber,
            policyNumber,
            DocumentPageService,
            modalContext,
            additionalDocMetadataPropsPV
        } = this.props;
        const documentMetaDataTemplate = {
            jobNumber: jobNumber,
            accountNumber: accountNumber,
            policyNumber: policyNumber,
            name: file.name,
            documentType: this.state.docType,
            sessionID: await this.generateUploadToken(),
            ...additionalDocMetadataPropsPV
        };

        this.setState({ isLoading: true });
        await DocumentPageService.uploadDocument(
            file, documentMetaDataTemplate, authHeader
        ).then((documentUploadData) => {
            if (!_.isEmpty(documentUploadData)) {
                return this.getDocuments();
            }
            return null;
        }).catch(() => {
            modalContext.showAlert({
                title: commonMessages.errorUploadTitle,
                message: commonMessages.uploadFailedMessage,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
        }).finally(() => {
            this.setState({ isLoading: false, docType: null, showDocDetails: false });
        });
    };

    deleteDocument = async (publicID) => {
        const { authHeader, DocumentPageService, modalContext } = this.props;

        this.setState({ isLoading: true });
        await DocumentPageService.removeDocument(publicID, authHeader).then((isDeleteItem) => {
            if (isDeleteItem) {
                this.getDocuments();
            }
        }).catch(() => {
            modalContext.showAlert({
                title: documentMessages.documentDeletionFailed,
                message: documentMessages.documentCannotDeleted,
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    };

    downloadDocument = (publicID, sessionID, source_PV) => {
        const { DocumentPageService } = this.props;
        return DocumentPageService.downloadDocument(
            publicID,
            sessionID,
            source_PV
        );
    };

    onDocumentTypeChange = (value) => {
        this.setState({ docType: value });
    }

    onUploadClick = () => {
        this.setState((state) => ({ showDocDetails: !state.showDocDetails, docType: null }));
    }

    static contextType = TranslatorContext;

    render() {
        const { policyNumber } = this.props;
        const { documentsData, isLoading } = this.state;
        const translator = this.context;
        const overrides = {
            documents: {
                initialDocumentsData: documentsData,
                uploadDocument: this.uploadDocument,
                deleteDocument: this.deleteDocument,
                documentTypeChange: this.onDocumentTypeChange,
                handleUploadClick: this.onUploadClick,
                policyNumber,
                isLoading: isLoading,
                docType: this.state.docType,
                showDocDetails: this.state.showDocDetails,
                downloadDocument: this.downloadDocument,
                noDataMessage: translator(messages.noPolicyChangeDocuments)
            }
        };
        const resolvers = {
            resolveComponentMap: {
                documentscomponent: DocumentComponent
            }
        };
        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    }
}

export default withAuthenticationContext(withDependencies(['DocumentPageService'])(withModalContext(DocumentsPage)));
