import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';

import { TranslatorContext } from '@jutro/locale';
import { Avatar } from '@jutro/components';
import { ServiceManager } from '@jutro/services';

import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { PhoneUtil } from '@xengage/gw-portals-viewmodel-js';
import { ContactService } from 'gw-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';

import metadata from './AccountContactsPage.metadata.json5';
import tabStyles from '../Contacts.module.scss';
import messages from '../Contacts.messages';
import gatewayMessages from '../../gateway.messages';

const PRIMARY_PHONE_TYPE_MAP = {
    home: 'homeNumber',
    mobile: 'cellNumber',
    work: 'workNumber'
};

class AccountContactsPage extends Component {
    static contextType = TranslatorContext;

    state = {
        accountContactRoleVM: {},
        accountContactRole: 'allRoles',
        accountContactRoleOptions: [],
        accountContactTableData: {},
    };

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        accountNumber: PropTypes.string.isRequired,
        relatedTo: PropTypes.string.isRequired,
        authHeader: PropTypes.shape({}).isRequired
    };

    writeValue = (value) => {
        this.setState({
            accountContactRole: value
        });
    };

    createVM = () => {
        const { viewModelService } = this.props;
        return viewModelService.create(
            {},
            'pc',
            'edge.capabilities.gateway.contact.dto.roles.account.AccountContactRoleDTO'
        );
    };

    componentDidMount = () => {
        const accountContactRoleVM = this.createVM();

        this.setState({ accountContactRoleVM }, () => {
            const accountContactRoleOptions = this.getContactRoles();
            this.setState({ accountContactRoleOptions });
        });
    };

    getAccountContacts = ({page ,pageSize}) => {
        const { accountContactRole, accountContactTableData } = this.state;
        const { accountNumber, authHeader } = this.props;
        const role = accountContactRole !== 'allRoles' ? [accountContactRole] : [];

        const maxPerPage = pageSize === 'All' ? accountContactTableData.maxNumberOfResults : pageSize;

        return ContactService.getAccountContacts(
            accountNumber,
            authHeader,
            role,
            page,
            maxPerPage
        ).then((response) => {
            this.setState({
                accountContactTableData: response
            });
            const data = this.getResponseData(response.accountContacts);
            return {
                rows: data,
                numberOfRows: response.maxNumberOfResults
            };
        });
    };

    getContactRoles = () => {
        const { accountContactRoleVM } = this.state;
        const translator = this.context;
        const allRolesOption = {
            code: 'allRoles',
            name: translator(messages.allRoles)
        };

        const contactRoles = _.get(accountContactRoleVM, 'subtype.aspects.availableValues', []);

        const roleOptions = contactRoles
            .filter((roles) => {
                return roles.code !== 'AccountContactRole';
            })
            .map((contacts) => {
                return {
                    code: contacts.code,
                    name: translator({
                        id: `gateway.contacts.common.views.roles.${contacts.code}`,
                        defaultMessage: `${contacts.code}`
                    })
                };
            });

        roleOptions.unshift(allRolesOption);

        return roleOptions;
    };

    getResponseData = (accountContacts) => {
        if (accountContacts) {
            const contactsInfo = accountContacts.map((contact) => {
                return {
                    contact: contact.displayName,
                    role: contact.accountContactRoles,
                    involvedWith: contact.associatedPoliciesCount,
                    active: contact.active
                };
            });
            return contactsInfo;
        }
        return [];
    };

    redirectToContactDetails = (index) => {
        const { accountContactTableData } = this.state;
        const { accountNumber, relatedTo, history } = this.props;
        const { accountContacts } = accountContactTableData;

        history.push({
            pathname: `/${relatedTo}/${accountNumber}/contactDetails`,
            state: {
                contact: 'accountContacts',
                accountOrPolicyNumber: accountNumber,
                relatedTo: relatedTo,
                contactId: accountContacts[index].accountContactPublicID
            }
        });
    };

    handleAccountContactSelect = (selectedOption) => {
        this.setState({
            accountContactRole: selectedOption
        });
        this.loadData(0, this.pageSize);
    };

    renderContact = (item, index, { path: property }) => {
        const { accountContactTableData } = this.state;
        if (index < accountContactTableData.accountContacts.length) {
            const contact = accountContactTableData.accountContacts[index];

            if (property === "phone") {

                if(!contact.primaryPhoneType) {
                    return null;
                }

                const phoneNumber = contact[PRIMARY_PHONE_TYPE_MAP[contact.primaryPhoneType]];
                const localeService = ServiceManager.getService('locale-service');
                const phoneUtil = PhoneUtil();
                const primaryPhoneNumber = phoneUtil.prettyPrint(
                    phoneNumber,
                    localeService.getDefaultCountryCode()
                );

                return (primaryPhoneNumber && (
                    <div className={tabStyles.gwContactPhoneNumber}>
                        {primaryPhoneNumber}
                    </div>
                ));
            }

            if (property === "email") {
                return (contact.emailAddress1 && (
                    <div className={tabStyles.gwContactEmail}>
                        {contact.emailAddress1}
                    </div>
                ));
            }

            const contactAvatarClass = cx(tabStyles.gwContactAvatar, {
                [tabStyles.gwActiveContactAvatar]: item.active,
                [tabStyles.gwInactiveContactAvatar]: !item.active
            });

            return (
                <div className={tabStyles.gwContactsTableWrapper}>
                    <div className={tabStyles.gwContactAvatarContainer}>
                        <Avatar
                            className={contactAvatarClass}
                            username={item[property]}
                            onClick={() => {
                                this.redirectToContactDetails(index);
                            }}
                        />
                    </div>
                    <div className={tabStyles.gwContactNameContainer}>
                        <div className={tabStyles.gwContactName}>{item[property]}</div>
                    </div>
                </div>
            );
        }

        return <div />;
    };

    renderRole = (item, index, { path: property }) => {
        const { accountContactRoleVM } = this.state;
        const translator = this.context;
        const roles = item[property];
        const roleOptions = accountContactRoleVM.subtype.aspects.availableValues;
        const getAllRoles = roles.map((data) => {
            const matchingRole = roleOptions.find((opt) => opt.code === data);
            return matchingRole ? (
                translator({
                    id: `gateway.contacts.common.views.roles.${matchingRole.code}`,
                    defaultMessage: `${matchingRole.code}`
                })
            ) : null;
        }).join(', ');

        return (
            <div title={getAllRoles}>
                { getAllRoles }
            </div>
        );
    };

    renderPoliciesInvolved = (item, index, { path: property }) => {
        const policiesCount = item[property];
        const isActive = item.active;

        const policiesInvolved = isActive
            ? (
                <TranslatorContext.Consumer>
                    {(translator) => {
                        const activePolicies = policiesCount > 1
                            ? translator(messages.activePolicies)
                            : translator(messages.activePolicy);
                        return (
                            <div className={tabStyles.gwInvolvedPoliciesContainer}>
                                <h4 className={tabStyles.gwInvolvedPolicies}>{policiesCount}</h4>
                                {activePolicies}
                            </div>
                        );
                    }}
                </TranslatorContext.Consumer>
            )
            : (
                <TranslatorContext.Consumer>
                    {(translator) => {
                        return (
                            translator(gatewayMessages.inactive)
                        );
                    }}
                </TranslatorContext.Consumer>
            );

        return <div>{policiesInvolved}</div>;
    };

    loadData = ({ page ,pageSize  }) => this.getAccountContacts({page ,pageSize});

    render() {
        const { accountContactRole, accountContactRoleOptions } = this.state;

        const overrideProps = {
            accountContactsDropdown: {
                availableValues: accountContactRoleOptions,
                value: accountContactRole
            },
            accountContactsDataTable: {
                key: accountContactRole,
                onFetchData: this.loadData
            }
        };

        const resolvers = {
            resolveClassNameMap: tabStyles,
            resolveCallbackMap: {
                renderContact: this.renderContact,
                renderRole: this.renderRole,
                renderPoliciesInvolved: this.renderPoliciesInvolved,
                handleAccountContactSelect: this.handleAccountContactSelect,
                onRowClick: (data, index) => {
                    this.redirectToContactDetails(index);
                }
            }
        };

        const readValue = (id, path) => {
            return readViewModelValue(
                metadata.pageContent,
                accountContactRole,
                id,
                path,
                overrideProps
            );
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                onValueChange={this.writeValue}
                resolveValue={readValue}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        );
    }
}

export const AccountContactsComponent = AccountContactsPage;
export default withRouter(withViewModelService(withAuthenticationContext(AccountContactsPage)));
