import React, { useContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { ScrollToError } from '@jutro/wizard-next';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { PVInsurersListService } from 'pv-capability-policyjob';
import { VehicleUtil } from 'pv-portals-util-js';
import { useModal } from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { messagesPolicyJob } from 'pv-capability-policyjob-react';

import metadata from './AdditionalInfoPage.metadata.json5';
import messages from './AdditionalInfoPage.messages';
import styles from './AdditionalInfoPage.module.scss';

const FIELDS_COVERT_UPPERCASE = ['license', 'vin'];
function AdditionalInfoPage(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const breakpoint = useContext(BreakpointTrackerContext);
    const { wizardData: submissionVM, updateWizardData, wizardSnapshot } = props;
    const { onValidate, isComponentValid, initialValidation, registerInitialComponentValidation } = useValidation('AdditionalInfoPage');
    const translator = useTranslator();
    const [showErrors, setShowErrors] = useState(false);
    const [errorTimestamp, setErrorTimestamp] = useState(0);
    const { authHeader } = useAuthentication();
    const [productCode] = useState(_.get(submissionVM, 'baseData.productCode.value'));
    const [brand] = useState(_.get(submissionVM, 'baseData.brand_PV.value.code'));
    const [lobVmPath] = useState(productCode === 'CommercialVehicle_PV' ? 'lobData.pcvCommercialAuto_PV' : 'lobData.ppvPersonalAuto_PV');
    const [insurers, setInsurers] = useState([]);

    const { showAlert } = useModal();

    const writeValue = useCallback(
        (value, path) => {
            let formattedValue = value;
            const newSubmissionVM = viewModelService.clone(submissionVM);
            if (path.includes("disability_PV")) {
                _.get(newSubmissionVM.value, `${lobVmPath}.coverables.drivers`, []).forEach((driver) => {
                    driver.medicalDisability_PV = false;
                    driver.medicalPermissionToDrive_PV = false;
                });
            }
            if (path.includes("licenceWithdrawal_PV")) {
                _.get(newSubmissionVM.value, `${lobVmPath}.coverables.drivers`, []).forEach((driver) => {
                    driver.person.licenceWithdrawel_PV = false;
                });
            }
            if (path.includes("driverCommitedOffense_PV") && !path.includes("drivers")) {
                _.get(newSubmissionVM.value, `${lobVmPath}.coverables.drivers`, []).forEach((driver) => {
                    driver.person.driverCommitedOffense_PV = false;
                });
            }
            FIELDS_COVERT_UPPERCASE.forEach((field) => {
                if (path.includes(field)) {
                    formattedValue = formattedValue?.toUpperCase();
                }
            });
            _.set(newSubmissionVM, path, formattedValue);
            updateWizardData(newSubmissionVM);
        },
        [lobVmPath, submissionVM, updateWizardData, viewModelService]
    );

    useEffect(() => {
        const newSubmissionVM = viewModelService.changeContext(submissionVM, {
            AdditionalQuestions: true,
            SummaryQuestions: false
        });
        updateWizardData(newSubmissionVM);
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function fetchData() {
            const list = await PVInsurersListService.getInsurersLists(authHeader);
            setInsurers(list.map((item) => {
                return {
                    name: item.currentInsurerName_PV,
                    code: item.publicID_PV
                };
            }));
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const shouldSkip = useCallback(() => {
        const vehicle_PV = _.get(submissionVM.value, `${lobVmPath}.coverables`);

        return (_.get(submissionVM, 'quoteData.portalPostQuote_PV')
            && !_.isUndefined(_.get(vehicle_PV, 'disability_PV'))
            && !_.isUndefined(_.get(vehicle_PV, 'licenceWithdrawal_PV'))
            && !_.isUndefined(_.get(vehicle_PV, 'driverCommitedOffense_PV'))
            && _.every(_.get(vehicle_PV, 'vehicles'),
                (vehicle) => {
                    return (((vehicle.financialPlan === true
                        && !_.isUndefined(vehicle.financialCompanyName_PV)
                        && !_.isUndefined(vehicle.financialCompanyType_PV)
                        && !_.isUndefined(vehicle.contractNumber_PV)
                        && !_.isUndefined(vehicle.contractEndDate_PV))
                        || (vehicle.financialPlan === false && _.isUndefined(vehicle.financialCompanyName_PV)))
                        && !_.isUndefined(vehicle.vin));
                }))
            || (submissionVM.baseData.periodStatus === 'Proposal_pv' || submissionVM.baseData.periodStatus === 'Bound');

    }, [lobVmPath, submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(shouldSkip);
    }, [registerInitialComponentValidation, shouldSkip]);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setShowErrors(true);
            setErrorTimestamp(Date.now());
            return false;
        }
        submissionVM.value = await LoadSaveService.updateAdditionalInfoPV(
            submissionVM.value,
            authHeader
        );
        return submissionVM;
    }, [isComponentValid, submissionVM, LoadSaveService, authHeader]);

    const onBlurVin = useCallback((index, oldVehicle) => {
        return (event, payload) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            const vehicles = _.get(submissionVM.value, `${lobVmPath}.coverables.vehicles`);
            const vehicleMatched = _.find(vehicles, (vehicle, vehicleIndex) => {
                return (vehicle.vin === payload.value && vehicleIndex !== index);
            });
            if (vehicleMatched) {
                _.set(newSubmissionVM, payload.model, null);
                updateWizardData(newSubmissionVM);
                showAlert({
                    title: messagesPolicyJob.vinNotUnique,
                    message: translator(messagesPolicyJob.vinMatchedMsg, {
                        firstVehicle: vehicleMatched.displayName,
                        secondVehicle: oldVehicle.displayName.value
                    }),
                    status: 'warning',
                    icon: 'mi-error-outline',
                    confirmButtonText: messages.yes
                });
            }
        };
    }, [lobVmPath, submissionVM, translator, updateWizardData, viewModelService, showAlert]);



    const generateOverrides = useCallback(() => {
        const overrideProps = {};

        _.get(submissionVM, `${lobVmPath}.coverables.vehicles.children`).forEach((vehicle, index) => {
            overrideProps[`vehicleDisplayName${index}`] = {
                content: VehicleUtil.getFullDisplayName(vehicle, translator)
            };
            overrideProps[`vin${index}`] = {
                onBlur: onBlurVin(index, vehicle),
                disabled: _.get(wizardSnapshot.value, `${lobVmPath}.coverables.vehicles[${index}]`).vin !== undefined
            };
            overrideProps[`financialCompany${index}`] = {
                visible: vehicle.financialPlan.value
            };
            overrideProps[`financialDetails${index}`] = {
                visible: vehicle.financialPlan.value,
                showPlanSelection: false,
                vehiclePath: `${lobVmPath}.coverables.vehicles[${index}]`,
                vehicleVM: vehicle,
                onValidate: onValidate,
                brand: brand
            };
            overrideProps[`cancenCurrentInsurerDetails${index}`] = {
                vehiclePath: `${lobVmPath}.coverables.vehicles[${index}]`,
                vehicleVM: vehicle,
                insurers: insurers,
                onValidate: onValidate
            };

        });
        return overrideProps;
    }, [insurers, submissionVM, lobVmPath, wizardSnapshot.value, translator, onBlurVin, onValidate]);

    const checkDriversInsurance = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            if (value && _.get(newSubmissionVM, `${lobVmPath}.coverables.drivers.value`).length === 1) {
                const selectDriver = `${lobVmPath}.coverables.drivers.value[0]`;
                if (path.includes('disability_PV')) {
                    _.set(newSubmissionVM, `${selectDriver}.medicalDisability_PV`, value);
                }
                if (path.includes('licenceWithdrawal_PV')) {
                    _.set(newSubmissionVM, `${selectDriver}.person.licenceWithdrawel_PV`, value);
                }
                if (path.includes('driverCommitedOffense_PV')) {
                    _.set(newSubmissionVM, `${selectDriver}.person.driverCommitedOffense_PV`, value);
                }
            }
            if (!value) {
                _.get(newSubmissionVM, `${lobVmPath}.coverables.drivers.value`).forEach((driver, index) => {
                    if (path.includes('disability_PV')) {
                        _.set(newSubmissionVM, `${lobVmPath}.coverables.drivers.value[${index}].medicalDisability_PV`, false);
                    }
                    if (path.includes('licenceWithdrawal_PV')) {
                        _.set(newSubmissionVM, `${lobVmPath}.coverables.drivers.value[${index}].person.licenceWithdrawel_PV`, false);
                    }
                    if (path.includes('driverCommitedOffense_PV')) {
                        _.set(newSubmissionVM, `${lobVmPath}.coverables.drivers.value[${index}].person.driverCommitedOffense_PV`, false);
                    }
                });
            }
            updateWizardData(newSubmissionVM);
        },
        [lobVmPath, submissionVM, updateWizardData, viewModelService]
    );

    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: breakpoint === 'desktop' ? 'left' : 'top'
        },
        driversInsuranceHeader: {
            visible: _.get(submissionVM, `${lobVmPath}.coverables.drivers.value`).length > 0
        },
        driverContainer: {
            visible: _.get(submissionVM, `${lobVmPath}.coverables.drivers.value`).length > 0
        },
        whichDriverDisabilityContainer: {
            visible: _.get(submissionVM.value, `${lobVmPath}.coverables.disability_PV`),
            path: `${lobVmPath}.coverables.drivers.children`
        },
        whichDriverWithdrawelContainer: {
            visible: _.get(submissionVM.value, `${lobVmPath}.coverables.licenceWithdrawal_PV`),
            path: `${lobVmPath}.coverables.drivers.children`
        },
        whichDriverOffenseContainer: {
            visible: _.get(submissionVM.value, `${lobVmPath}.coverables.driverCommitedOffense_PV`),
            path: `${lobVmPath}.coverables.drivers.children`
        },
        vehicleContainer: {
            path: `${lobVmPath}.coverables.vehicles.children`
        },
        disability: {
            path: `${lobVmPath}.coverables.disability_PV`
        },
        licenseWithdrawal: {
            path: `${lobVmPath}.coverables.licenceWithdrawal_PV`
        },
        driverCommitedOffense: {
            path: `${lobVmPath}.coverables.driverCommitedOffense_PV`
        },
        ...generateOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate,
            checkDisability: checkDriversInsurance,
            checkLicenseWithdrawal: checkDriversInsurance,
            checkDriverCommitedOffense: checkDriversInsurance
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            showCancel={false}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                showErrors={showErrors}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
            <ScrollToError counter={errorTimestamp} timeout={200} />
        </WizardPage>
    );
}

AdditionalInfoPage.propTypes = wizardProps;
export default AdditionalInfoPage;
