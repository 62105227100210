import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useModal, CurrencyValue } from '@jutro/components';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as platformMessagesPV } from 'pv-platform-translations';
import { BusinessConstant, EdgeErrorParser } from 'pv-portals-util-js';
import { gatewayMessages } from 'gw-capability-gateway-react';

import PackParametersModal from '../PackParametersModal/PackParametersModal';

import metadata from './PremiumDetailsComponent.metadata.json5';
import messages from './PremiumDetailsComponent.messages';
import styles from './PremiumDetailsComponent.module.scss';

function getTotalPremium(submission, quotePremiumPath) {
    const premium = _.get(submission, `${quotePremiumPath}.total`);
    return (
        <FormattedMessage
            {...messages.totalPremium}
            values={{
                premium: (
                    <CurrencyValue
                        id="premium"
                        value={premium}
                        className={styles.currencyValue}
                        showFractions
                    />
                )
            }}
        />
    );
};

function PremiumDetailsComponent(props) {
    const {
        value: jobVM,
        showRequired,
        labelPosition,
        phoneWide,
        disabled,
        onCalculatePremium,
        showRecalculateButton,
        onValidatePage,
        showError,
        onCheckPremiumDetailsValid,
        onValueChange
    } = props;

    const history = useHistory();
    const lobName = _.get(jobVM, 'baseData.productCode.value') === BusinessConstant.PPV_PROD_CODE ? BusinessConstant.PPV_LOB_CODE : BusinessConstant.PCV_LOB_CODE;
    const customBranchIdx = jobVM.value.lobData[lobName].offerings.findIndex((offering) => offering.branchCode === BusinessConstant.BRANCH_CODE_CUSTOM);
    const offeringCoveragesPath = `lobData.${lobName}.offerings[${customBranchIdx !== -1 ? customBranchIdx : 0}].coverages`;

    const offeredQuotesCustomBranchIdx = jobVM.value.quoteData?.offeredQuotes.findIndex((quote) => quote.branchCode === BusinessConstant.BRANCH_CODE_CUSTOM) || 0;
    const quotePremiumPath = `quoteData.offeredQuotes[${offeredQuotesCustomBranchIdx !== -1 ? offeredQuotesCustomBranchIdx : 0}].premium`;

    const { LoadSaveService } = useDependencies('LoadSaveService');
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const [premiumDifference, updatePremiumDifference] = useState(0);
    const [oldTotalPremium, updateOldTotalPremium] = useState(_.get(jobVM.value, `${quotePremiumPath}.total`));
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [hasDiscountError, setHasDiscountError] = useState(false);

    const { showAlert } = useModal();

    const quickQuoteMode = _.get(jobVM, 'baseData.quoteType.value.code') === 'Quick';
    const jobType = _.get(jobVM, 'baseData.jobType.value.code'); // Submission vs. PolicyChange
    const isJobQuoted = _.get(jobVM, `${jobType === BusinessConstant.JOB_TYPE_SUBMISSION ? 'baseData.periodStatus' : 'status'}.value.code`) === 'Quoted';
    const jobQuotedAndClean = isJobQuoted && !showRecalculateButton;
    const jobID = _.get(jobVM, 'quoteID.value') || _.get(jobVM, 'jobID.value');
    const sessionUUID = _.get(jobVM, 'sessionUUID.value');
    const branchName = _.get(jobVM, `value.lobData.${lobName}.offerings[${customBranchIdx}].branchName`);

    const handleError = useCallback(({ title, message, confirmationButtonText = platformMessages.ok, redirectToSummaryPage = true }) => {
        showAlert({
            title: title,
            message: message,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: confirmationButtonText
        }).then(() => {
            if (redirectToSummaryPage) {
                const urlJob = jobType === BusinessConstant.JOB_TYPE_SUBMISSION ? 'quotes' : 'change';
                history.push(`/${urlJob}/${jobID}/summary`);
            }
        }, _.noop);
    }, [history, jobID, jobType, showAlert]);

    const getSelectedPlanPremium = useCallback((submission) => {
        const premium = _.get(submission, `${quotePremiumPath}.amountAccordingSelectedPaymentPlan_PV`);
        const paymentPlanPath = `lobData.${lobName}.offerings[${customBranchIdx}].paymentFrequency`;
        const planName = _.get(submission, paymentPlanPath);
        return (
            <FormattedMessage
                {...messages.selectedPaymentPlan}
                values={{
                    premium: (
                        <CurrencyValue
                            id="premium"
                            value={premium}
                            className={styles.currencyValue}
                            showFractions
                        />
                    ),
                    plan: translator({
                        id: `typekey.BillingPeriodicity.${planName}`,
                        defaultMessage: planName
                    })
                }}
            />
        );
    }, [lobName, translator, customBranchIdx, quotePremiumPath]);

    const calculatePremium = useCallback(async () => {

        // artificial way to "sleep" browser for given milliseconds
        function delay(milliseconds){
            return new Promise((resolve) => {
                setTimeout(resolve, milliseconds);
            });
        }

        onCheckPremiumDetailsValid(true);
        setHasDiscountError(false);
        if (!onValidatePage(true)) {
            return;
        }
        try {
            let response;
            try {
                response = await LoadSaveService.getPremium_PV(jobVM.value, authHeader);
            } catch (error) {
                if (EdgeErrorParser.isRetryableError(error)) {
                    const msg = EdgeErrorParser.getErrorMessage(error);
                    console.warn("Data concurrentcy error, retrying one more time", msg);

                    // wait & retry
                    // NOTE: it can be easily improved to retry N times e.g. like e-signature, e-payment

                    await delay(5000);
                    response = await LoadSaveService.getPremium_PV(jobVM.value, authHeader);
                } else {
                    // if not retryable, continue standard error handling
                    throw error;
                }
            }
            jobVM.value = response;
            onCalculatePremium(jobVM);
            if (response.quoteData) {
                if (!_.isUndefined(oldTotalPremium)) {
                    const newTotalPremium = _.get(jobVM.value, `${quotePremiumPath}.total`);
                    const amountDifference = newTotalPremium.amount - oldTotalPremium.amount;
                    updatePremiumDifference(amountDifference);
                }
                updateOldTotalPremium(_.get(jobVM.value, `${quotePremiumPath}.total`));
            }
        } catch (error) {
            if (EdgeErrorParser.isUWIssueError(error)) {
                handleError({
                    title: jobType === BusinessConstant.JOB_TYPE_SUBMISSION ? platformMessagesPV.uwIssueErrorTitle : platformMessagesPV.unableToQuoteTitle,
                    message: jobType === BusinessConstant.JOB_TYPE_SUBMISSION ? platformMessagesPV.uwIssueErrorMsg : platformMessagesPV.unableToQuoteMsg,
                    confirmationButtonText: platformMessagesPV.uwIssueErrorBtn
                });
            } else if (EdgeErrorParser.isValidationDiscountError(error)) {
                onCheckPremiumDetailsValid(false);
                setHasDiscountError(true);
                handleError({
                    title: translator(gatewayMessages.modalError),
                    message: platformMessagesPV.discountError,
                    confirmationButtonText: platformMessages.close,
                    redirectToSummaryPage: false
                });
            } else if (EdgeErrorParser.isValidationsError(error)) {
                const msg = EdgeErrorParser.getErrorMessage(error);
                handleError({
                    message: msg,
                    redirectToSummaryPage: false
                });
            }  else {
                const msg = EdgeErrorParser.getErrorMessage(error);
                handleError({
                    title: platformMessagesPV.genericTechnicalErrorTitle,
                    message: `${translator(platformMessagesPV.genericTechnicalErrorMsg)}\n${msg}`,
                    redirectToSummaryPage: false
                });
            }
        } finally {
            setLoading(false);
            setShowModal(false);
        }
    }, [onCheckPremiumDetailsValid, onValidatePage, LoadSaveService, jobVM, authHeader, onCalculatePremium, oldTotalPremium, handleError, jobType, translator, quotePremiumPath]);

    const handleClickParameters = useCallback(
        () => {
            setShowModal(true);
            updatePremiumDifference(0);
        }, []
    );

    const updateDiscountsAndCommissionsOnClose = useCallback(() => {
        const copyCommissionsData = _.clone(_.get(jobVM.value, offeringCoveragesPath));
        copyCommissionsData.discounts.forEach((discount) => {
            discount.appliedValue = discount.staleValue;
            discount.updated = true;
        });
        copyCommissionsData.commissions && copyCommissionsData.commissions.forEach((commission) => {
            commission.commissionOverride = commission.staleValue;
            commission.updated = true;
        });
        _.set(jobVM.value, offeringCoveragesPath, copyCommissionsData);
        onValueChange(copyCommissionsData, `${offeringCoveragesPath}`);
    }, [offeringCoveragesPath, onValueChange, jobVM]);

    const closeParametersModal = useCallback(() => {
        updateDiscountsAndCommissionsOnClose();
        setShowModal(false);
    }, [updateDiscountsAndCommissionsOnClose]);

    const saveParameters = useCallback(() => {
        setLoading(true);
        calculatePremium();
    }, [calculatePremium]);

    const overrideProps = {
        '@field': {
            showRequired,
            labelPosition,
            phoneWide,
            disabled,
            showError
        },
        '@action': {
            disabled
        },
        printDocumentLabel: {
            visible: jobType === BusinessConstant.JOB_TYPE_POLICY_CHANGE && !quickQuoteMode && jobQuotedAndClean
        },
        downloadDocumentContainer: {
            className: jobType === BusinessConstant.JOB_TYPE_POLICY_CHANGE && !quickQuoteMode && jobQuotedAndClean ? 'documentIconInline' : 'documentIcon'
        },
        printDocument: {
            visible: !quickQuoteMode && jobQuotedAndClean,
            quoteID: jobID,
            sessionUUID,
            branchName,
            toProducer: jobType === BusinessConstant.JOB_TYPE_SUBMISSION
        },
        calculateButton: {
            visible: !isJobQuoted && !showRecalculateButton
        },
        recalculateButton: {
            visible: showRecalculateButton
        },
        totalPremiumContainer: {
            visible: jobQuotedAndClean || hasDiscountError
        },
        submissionPremiumContainer: {
            visible: jobType === BusinessConstant.JOB_TYPE_SUBMISSION
        },
        policyChangePremiumContainer: {
            visible: jobType === BusinessConstant.JOB_TYPE_POLICY_CHANGE
        },
        totalPremium: {
            content: getTotalPremium(jobVM.value, quotePremiumPath)
        },
        premiumDifference: {
            visible: !_.isUndefined(premiumDifference) && premiumDifference !== 0,
            value: premiumDifference
        },
        selectedPlanPremium: {
            visible: jobType === BusinessConstant.JOB_TYPE_SUBMISSION && jobQuotedAndClean,
            content: getSelectedPlanPremium(jobVM.value)
        },
        packParametersModal: {
            visible: showModal,
            submissionVM: jobVM,
            isLoading
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            calculatePremium,
            handleClickParameters,
            saveParameters,
            closeParametersModal,
            updateParameters: onValueChange
        },
        resolveComponentMap: {
            PackParametersModal
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={jobVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PremiumDetailsComponent.propTypes = {
    value: PropTypes.shape({}).isRequired,
    showRequired: PropTypes.bool.isRequired,
    labelPosition: PropTypes.string.isRequired,
    phoneWide: PropTypes.shape({}).isRequired,
    disabled: PropTypes.bool.isRequired,
    onCalculatePremium: PropTypes.func.isRequired,
    showRecalculateButton: PropTypes.bool.isRequired,
    onValidatePage: PropTypes.func.isRequired
};

export default PremiumDetailsComponent;
