import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { MetadataContent } from '@jutro/uiconfig';
import { InputField, Card } from '@jutro/components';
import { intlMessageShape } from '@jutro/prop-types';
import FaqItemComponent from '../FaqItemComponent/FaqItemComponent';
import styles from './FaqSectionComponent.module.scss';

import metadata from './FaqSectionComponent.metadata.json5';

function FaqSectionComponent(props) {
    const translator = useTranslator();
    const { value, searchText, messages } = props;

    const readValue = useCallback(
        (id, compPath) => {
            let effectivePath = compPath;

            if (compPath === undefined) {
                effectivePath = id;
            }

            const currentSection = _.get(value, `${effectivePath}`);

            return currentSection;
        },
        [value]
    );

    const resolvers = {
        resolveValue: readValue,
        resolveComponentMap: {
            faqitemcomponent: FaqItemComponent
        }
    };

    const generateTopicOverrides = useCallback(() => {
        const faqTopics = _.get(value, 'topics');
        const overrides = faqTopics.map((_section, index) => {
            return { [`faqTopicComponent${index}`]: { searchText: searchText, messages } };
        });

        return Object.assign({}, ...overrides);
    }, [messages, searchText, value]);

    const overrideProps = {
        faqTopicsIterable: {
            accordionStates: ['accordion_gw-faq-deductible-payment'],
            data: _.get(value, 'topics')
        },
        ...generateTopicOverrides()
    };

    return (
        <div
            className={styles.gwSectionContainer}
        >
            <Card id="faqSectionContainer" fullWidth className={styles.faqSection} isPanel>
                <InputField
                    id={value.id}
                    readOnly
                    hideLabel
                    value={translator(value.title)}
                />
                <MetadataContent
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    {...resolvers} />
            </Card>
        </div>
    );
}

FaqSectionComponent.propTypes = {
    value: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: intlMessageShape.isRequired
    }).isRequired,
    searchText: PropTypes.string.isRequired,
    messages: PropTypes.shape({}).isRequired
};
export default FaqSectionComponent;
