import React, { useState } from 'react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, Button } from '@jutro/components';
import metadata from './InvoiceConfirmationPopup.metadata.json';
import messages from './InvoiceConfirmationPopup.messages.js';
import gatewayMessages from '../CollectionMethodChangePopup/CollectionMethodChangePopup.messages.js';

function InvoiceConfirmationPopup(props) {
    const {
        invoicePayment,
        invoicePayment: {
            eventType,
            invoiceNumber,
            invoiceAmount,
            isPaymentDone,
            accountNo
        },
        onHandleCancel,
        onHandleConfirm
    } = props;
    const [paymentReceived, setPaymentReceived] = useState(isPaymentDone);
    const translator = useTranslator();

    const titleMsg = translator(messages.titleLabel);
    const cancelMsg = translator(gatewayMessages.cancelLabel);
    const okMsg = translator(messages.okLabel);

    const resolvers = {
        resolveCallbackMap: {
            handlePayment: setPaymentReceived
        },
    };

    const overrides = {
        isPaymentDone: {
            value: paymentReceived
        }
    };

    return (
        <ModalNext isOpen>
            <ModalHeader title={titleMsg} />
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    callbackMap={resolvers.resolveCallbackMap}
                    overrideProps={overrides}
                    model={invoicePayment}
                />
            </ModalBody>
            <ModalFooter>
                <Button type="outlined" onClick={onHandleCancel}>
                    {cancelMsg}
                </Button>
                <Button disabled={_.isNil(paymentReceived)} onClick={() => onHandleConfirm(accountNo, invoiceNumber, eventType, invoiceAmount, paymentReceived)}>
                    {okMsg}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

export default InvoiceConfirmationPopup;
