import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { BusinessConstant as BC } from 'pv-portals-util-js';

import PremiumInfo from './PremiumInfo/PremiumInfo';
import metadata from './PremiumsDetails.metadata.json5';
import styles from './PremiumsDetails.module.scss';
import './PremiumsDetails.messages.js';

function PremiumsDetails(props) {
    const {
        submissionVM,
        pageLoBType
    } = props;
    const [offeringsIndexPath] = useState(_.get(submissionVM, `lobData.${pageLoBType}.offerings.value`).findIndex((offering) => offering.branchCode === BC.BRANCH_CODE_CUSTOM) || 0);

    const generatPremiumOverrides =  useCallback(() => {
        const vehicleCoverages =  _.get(submissionVM, `lobData.${pageLoBType}.offerings.value`)[offeringsIndexPath].coverages.vehicleCoverages;

        const overrides = vehicleCoverages.map((vehicleCoverage, index) => {
            return {
                [`premiumDetails${index}`]: {
                    heading: _.get(vehicleCoverage, "vehicleName")
                },
                [`premium${index}`]: {
                    submissionVM,
                    pageLoBType
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [submissionVM, pageLoBType, offeringsIndexPath]);

    const overrideProps = {
        '@field': {
            labelPosition: 'top',
            readOnly: true
        },
        premiumList: {
            defaultActiveTab: 'premiumDetails0',
            path: `lobData.${pageLoBType}.offerings.value.${offeringsIndexPath}.coverages.vehicleCoverages`
        },
        ...generatPremiumOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            PremiumInfoComponent: PremiumInfo
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PremiumsDetails.propTypes = {
    submissionVM: PropTypes.shape({}),
    pageLoBType: PropTypes.string
};

PremiumsDetails.defaultProps = {
    submissionVM: {},
    pageLoBType: undefined
};

export default PremiumsDetails;
