import { defineMessages } from 'react-intl';

export default defineMessages({
    policies: {
        id: 'gateway.views.policy-landing.Policies',
        defaultMessage: 'Contracts',
    },
    recentlyIssuedPolicyForPastXDays: {
        id: 'gateway.views.Recently Issued Policies for past x days',
        defaultMessage: 'Recently Issued Contracts for past {value} days',
    },
    recentlyViewedPolicies: {
        id: 'gateway.views.policy-landing.Recently Viewed Policies',
        defaultMessage: 'Recently Viewed Contracts'
    },
    recentlyViewed: {
        id: 'gateway.views.policy-landing.Recently Viewed',
        defaultMessage: 'Recently Viewed',
    },
    recentlyIssued: {
        id: 'gateway.views.policy-landing.Recently Issued',
        defaultMessage: 'Recently Issued',
    },
    delinquent: {
        id: 'gateway.views.policy-landing.Delinquent',
        defaultMessage: 'Delinquent',
    },
    delinquentPolicies: {
        id: 'gateway.views.policy-landing.Delinquent Policies',
        defaultMessage: 'Delinquent Contracts',
    },
    openQuotes: {
        id: 'gateway.views.policy-landing.Open Quotes',
        defaultMessage: 'Open Quotes',
    },
    openChanges: {
        id: 'gateway.views.policy-landing.Open Changes',
        defaultMessage: 'Open Changes'
    },
    openCancellations: {
        id: 'gateway.views.policy-landing.Open Cancellations',
        defaultMessage: 'Open Cancellations'
    },
    openRenewals: {
        id: 'gateway.views.policy-landing.Open Renewals',
        defaultMessage: 'Open Renewals'
    },
    product: {
        id: 'gateway.views.policy-landing.Product',
        defaultMessage: 'Product'
    },
    status: {
        id: 'gateway.views.policy-landing.Status',
        defaultMessage: 'Status'
    },
    policyNumber: {
        id: 'gateway.views.policy-landing.PolicyNumber',
        defaultMessage: 'Policy Number'
    },
    account: {
        id: 'gateway.views.policy-landing.Account',
        defaultMessage: 'Account'
    },
    namedInsured: {
        id: 'gateway.views.policy-landing.NamedInsured',
        defaultMessage: 'Named Insured'
    },
    inGoodStanding: {
        id: 'gateway.views.policy-landing.In Good Standing',
        defaultMessage: 'In Good Standing'
    },
    billingStatus: {
        id: 'gateway.views.policy-landing.Billing Status',
        defaultMessage: 'Billing Status'
    },
    policyLandingQuotes: {
        id: 'gateway.views.policy-landing.Quotes',
        defaultMessage: 'Quotes'
    },
    policyLandingRenewal: {
        id: 'gateway.views.policy-landing.Renewal',
        defaultMessage: 'Renewal'
    },
    policyLandingChange: {
        id: 'gateway.views.policy-landing.Change',
        defaultMessage: 'Change'
    },
    policyLandingCancellation: {
        id: 'gateway.views.policy-landing.Cancellation',
        defaultMessage: 'Cancellation'
    },
    quickFilter: {
        id: 'gateway.views.policy-landing.Quick Filters',
        defaultMessage: 'Quick Filters'
    },
    advancedFilter: {
        id: 'gateway.views.policy-landing.Advanced Filter',
        defaultMessage: 'Advanced Filter'
    },
    switchTo: {
        id: 'gateway.views.policy-landing.Switch to',
        defaultMessage: 'Switch to'
    },
    premium: {
        id: 'gateway.views.policy-landing.Premium',
        defaultMessage: 'Premium'
    },
    created: {
        id: 'gateway.views.policy-landing.Created',
        defaultMessage: 'Created'
    },
    jobNumber: {
        id: 'gateway.views.policy-landing.JobNumber',
        defaultMessage: 'Job Number'
    },
    jobStatus: {
        id: 'gateway.views.policy-landing.Job Status',
        defaultMessage: 'Job Status'
    },
    quotes: {
        id: 'gateway.views.policy-landing.Quotes',
        defaultMessage: 'Quotes'
    },
    renewal: {
        id: 'gateway.views.policy-landing.Renewal',
        defaultMessage: 'Renewal'
    },
    change: {
        id: 'gateway.views.policy-landing.Change',
        defaultMessage: 'Change'
    },
    accountTableHeading: {
        id: 'gateway.views.for past x days',
        defaultMessage: '{currentView} for past {value} days'
    },
    noResultsFound: {
        id: 'gateway.views.policy-landing.No results found for this filter',
        defaultMessage: 'No results found for this filter'
    },
    effectiveDate: {
        id: 'gateway.views.policy-landing.Effective Date',
        defaultMessage: 'Effective Date'
    },
    expirationDate: {
        id: 'gateway.views.policy-landing.Expiration Date',
        defaultMessage: 'Expiration Date'
    },
    accountNavigation: {
        id: 'gateway.navigation.breadcrumbs.Account: link',
        defaultMessage: 'Account: {link}'
    },
    triggerBlocRetour: {
        id: 'gateway.views.policy-detail.Trigger bloc retour',
        defaultMessage: 'Trigger contract bloc retour'
    },
    blocRetourRequested: {
        id: 'gateway.views.policy-detail.Bloc retour requested',
        defaultMessage: 'Contract bloc retour requested'
    }
});
