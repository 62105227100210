import React, { Component } from 'react';
import { Chevron, Link, withModalContext } from '@jutro/components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import _ from 'lodash';
import moment from 'moment';
import {
    TranslatorContext, withIntl
} from '@jutro/locale';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { PolicyService, UserService } from 'gw-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { JobUtil, DatatableUtil, LocalDateUtil } from '@xengage/gw-portals-util-js';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ServiceManager } from '@jutro/services';
import { PhoneUtil } from '@xengage/gw-portals-viewmodel-js';
import { LoadSaveService } from 'gw-capability-gateway-quoteandbind';
import { GatewayEndorsementService } from 'gw-capability-gateway-policychange';
import { BusinessConstant } from 'pv-portals-util-js';
import CoveragesIterableComponent from '../../Components/CoveragesIterableComponent/CoveragesIterableComponent';
import CopyContractComponent from '../../Components/CopyContractComponent/CopyContractComponent';
import PolicyDwellingInfo from './ho/PolicyDwellingInfo/PolicyDwellingInfo';
import PolicyVehiclesDriversInfo from './pa/PolicyVehiclesDriversInfo/PolicyVehiclesDriversInfo';
import Cancellation from '../Cancellation/Cancellation';
import ChangePolicy from '../ChangePolicy/ChangePolicy';
import metadata from './Summary.metadata.json5';
import styles from './Summary.module.scss';
import messages from './Summary.messages';
import gatewayMessages from '../../gateway.messages';

function getCell(items, index, property) {
    return items[property.id];
}

function transactionTypeIsViewableInXCenter(transaction) {
    return (
        !_.isUndefined(transaction.type) && transaction.type.toLowerCase() !== 'reinstatement'
        && !_.isUndefined(transaction.type) && transaction.type.toLowerCase() !== 'rewrite'
    );
}

function canUserOpenJobInXCenter(transaction) {
    return transaction.canUserView && transactionTypeIsViewableInXCenter(transaction) && transaction.type !== 'Issuance'
        && transaction.status.toLowerCase() !== 'declined'
        && transaction.status.toLowerCase() !== 'nottaken'
        && transaction.status.toLowerCase() !== 'withdrawn';
}

const formatToShortDate = (dateObj) => moment(dateObj).format('DD/MM/yyyy');

class Summary extends Component {
    static propTypes = {
        fromAccountLanding: PropTypes.shape({
            policyDetailsData: PropTypes.shape({
                policyResponse: PropTypes.shape({
                    policyNumber: PropTypes.string
                })
            })
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        intl: PropTypes.func.isRequired,
    };

    static contextType = TranslatorContext;

    state = {
        policyData: '',
        disableCancelLink: true,
        disableChangePolicyLink: true,
        policyTransactionData: [],
        policyTransactionStatusValues: [],
        selectedTransactionStatus: 'all',
        searchTransactionKeyword: undefined,
        canAdvanceCancellation: false,
        canEditPolicy: false,
        selectedTransactionDate: LocalDateUtil.today(),
        showModal: false
    };

    componentDidMount = () => {
        this.hasAdvanceCancellationPermission();
        this.hasEditPolicyPermission();
        this.setPolicyCoveragesDetails();
        this.getPolicyTransactions();
    };

    componentDidUpdate(prevProp) {
        if (prevProp.policyDetailsData !== this.props.policyDetailsData) {
            this.setPolicyCoveragesDetails();
            this.getPolicyTransactions();
        }
    }

    setPolicyCoveragesDetails = () => {
        const {
            fromAccountLanding: { policyDetailsData }
        } = this.props;
        const policyData = _.cloneDeep(policyDetailsData.policyResponse);
        this.setState({ policyData });
    };

    getParametersData = async (transactions, policyData) => {
        const { authHeader, viewModelService } = this.props;

        const jobData = _.first(
            _.orderBy(
                transactions.filter(
                    ({ type }) =>
                        type === BusinessConstant.JOB_TYPE_POLICY_CHANGE || type === BusinessConstant.JOB_TYPE_SUBMISSION
                ),
                ({ createTime }) => new Date(createTime),
                'desc'
            )
        );

        if (jobData) {
            try {
                const { postalCode } = policyData.account.accountHolder.primaryAddress;
                const isJobPolicyChange = jobData.type === BusinessConstant.JOB_TYPE_POLICY_CHANGE;

                const jobRetrieval = isJobPolicyChange
                    ? GatewayEndorsementService.loadEndorsement
                    : LoadSaveService.retrieveSubmission;
                const payload = isJobPolicyChange
                    ? policyData.policyNumber
                    : {
                        quoteID: jobData.jobNumber,
                        postalCode
                    };

                const submissionVM = await jobRetrieval(payload, authHeader);

                this.setState({
                    submissionVM: viewModelService.create(
                        submissionVM,
                        'pc',
                        isJobPolicyChange
                            ? 'edge.capabilities.policychange.dto.PolicyChangeDataDTO'
                            : 'edge.capabilities.quote.submission.dto.QuoteDataDTO'
                    )
                });
            } catch (error) {
                console.error(error);
            }
        }
    }

    getPolicyTransactions = async () => {
        const {
            authHeader,
            fromAccountLanding: {
                policyDetailsData: { policyResponse }
            }
        } = this.props;
        const { policyNumber } = policyResponse;

        const transactionResponse = await PolicyService.getPolicyTransactionsForPolicy(
            policyNumber,
            authHeader
        );
        this.getParametersData(transactionResponse, policyResponse);
        this.getMappedTransactionData(transactionResponse);
        const policyTransactionStatusValues = this.getPolicyTransactionStatus();
        this.setState({
            transactionResponse,
            policyTransactionStatusValues,
            selectedTransactionStatus: policyTransactionStatusValues[0].code
        });

    };

    getPhoneNumber = (accountHolder) => {
        let primaryPhoneNumber = '';
        const localeService = ServiceManager.getService('locale-service');
        const phoneUtil = PhoneUtil();
        switch (accountHolder.primaryPhoneType) {
            case 'work':
                primaryPhoneNumber = accountHolder.workNumber;
                break;
            case 'home':
                primaryPhoneNumber = accountHolder.homeNumber;
                break;
            case 'mobile':
                primaryPhoneNumber = accountHolder.cellNumber;
                break;
            default:
                primaryPhoneNumber = '';
        }
        return phoneUtil.prettyPrint(primaryPhoneNumber, localeService.getDefaultCountryCode());
    };

    setupAccountContactDetails = (accountHolder) => {
        const newPolicyData = {
            accountInfo: {
                subtype: accountHolder.subtype,
                firstName: accountHolder.firstName,
                lastName: accountHolder.lastName,
                primaryAddress: accountHolder.primaryAddress,
                primaryPhoneNumber: this.getPhoneNumber(accountHolder),
                contactName: accountHolder.subtype === 'Person' ? accountHolder.displayName : accountHolder.contactName
            }
        };
        this.setState((state) => ({
            policyData: { ...state.policyData, ...newPolicyData }
        }));
    };

    mapAccountDetails = (accountDetails) => {
        if (!accountDetails) {
            return;
        }
        const newPolicyData = {
            accountInfo: {
                publicID: accountDetails.publicID,
                policySummaries: accountDetails.policySummaries,
                accountNumber: accountDetails.accountNumber,
                canCreateSub: accountDetails.canUserCreateSubmission
            }
        };
        this.setState((state) => ({
            policyData: { ...state.policyData, ...newPolicyData }
        }));
        this.setupAccountContactDetails(accountDetails.accountHolder);
    };

    updatePolicyData = async (policyAsOfDate) => {
        const { authHeader, fromAccountLanding, modalContext } = this.props;
        const policyNumber = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.policyNumber'
        );
        if (policyAsOfDate) {
            await PolicyService.getPolicyAsOfDate(
                policyNumber,
                policyAsOfDate,
                authHeader
            ).then((transactionResponse) => {
                if (!_.isUndefined(transactionResponse.policyNumber)) {
                    const newPolicyData = {
                        policy: transactionResponse,
                        accountInfo: {},
                        latestPeriod: transactionResponse.latestPeriod,
                        product: transactionResponse.product,
                        account: { accountNumber: transactionResponse.account.accountNumber },
                        lobs: transactionResponse.lobs,
                        coverables: transactionResponse.coverables
                    };
                    this.mapAccountDetails(transactionResponse.account);
                    this.setState((state) => ({
                        policyData: { ...state.policyData, ...newPolicyData }
                    }));
                }
                if (_.isUndefined(transactionResponse.policyNumber)) {
                    const translator = this.context;
                    return modalContext.showAlert({
                        message: translator(messages.noPolicy, {
                            policyNumber: policyNumber,
                        }),
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: messages.close
                    });
                }
                if (!_.isUndefined(transactionResponse.error && transactionResponse.error.message)) {
                    const translator = this.context;
                    return modalContext.showAlert({
                        message: transactionResponse.error.message.includes(policyNumber) ?
                            translator(messages.noPolicyInEffect, {
                                asOfDate: formatToShortDate(policyAsOfDate),
                            }) : translator(messages.noPolicy, {
                                policyNumber: `${policyNumber}` || '',
                            }),
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: messages.close
                    });
                }
            }
            ).catch(() => {
                const translator = this.context;
                modalContext.showAlert({
                    message: translator(messages.noPolicyInEffect, {
                        asOfDate: formatToShortDate(policyAsOfDate),
                    }),
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: messages.close
                }).catch(_.noop);
                return false;
            });
        }
    };

    getFormattedCurrency = (premiumAmount, index) => {
        return (
            <CurrencyField
                id={`currency${index}`}
                value={premiumAmount}
                readOnly
                hideLabel
                showOptional={false}
                className={styles.currencyStyle}
            />
        );
    };

    getMappedTransactionData = (policyTransactionResponse, filter) => {
        const selectedStatus = _.get(filter, 'selectedTransactionStatus');
        const searchKeyword = _.get(filter, 'searchTransactionKeyword');
        const transactionData = _.cloneDeep(policyTransactionResponse);

        let policyTransactionData = transactionData.map((policy, index) => {
            return {
                jobNumber: policy.jobNumber,
                status: policy.status,
                displayType: policy.displayType,
                policyDisplayStatus: policy.policyDisplayStatus,
                totalPremium: this.getFormattedCurrency(policy.totalPremium, index),
                policyEffectiveDate: this.formatDate(policy.policyEffectiveDate),
                canUserView: policy.canUserView,
                type: policy.type,
                createTime: policy.createTime
            };
        }).sort((a, b) => {
            if (a.policyEffectiveDate === b.policyEffectiveDate) {
                return new Date(b.createTime).getTime()
                    - new Date(a.createTime).getTime();
            }
            return new Date(b.policyEffectiveDate).getTime()
                - new Date(a.policyEffectiveDate).getTime();
        });
        if (selectedStatus && selectedStatus !== 'all') {
            policyTransactionData = _.filter(policyTransactionData, (transaction) => {
                return transaction.status === selectedStatus;
            });
        }
        if (searchKeyword) {
            policyTransactionData = this.getSearchFilterValues(
                policyTransactionData,
                searchKeyword
            );
        }
        this.setState({
            policyTransactionData
        });
    };

    hasAdvanceCancellationPermission = () => {
        const { authHeader } = this.props;
        const permissionDTO = {
            permission: 'advancecancellation'
        };
        UserService.hasUserSystemPermission(permissionDTO, authHeader).then(
            (canAdvanceCancellation) => {
                this.setState({ canAdvanceCancellation });
            }
        );
    };

    hasEditPolicyPermission = () => {
        const { authHeader } = this.props;
        const permissionDTO = {
            permission: 'editpolchange'
        };
        UserService.hasUserSystemPermission(permissionDTO, authHeader).then(
            (canEditPolicy) => {
                this.setState({ canEditPolicy });

                if (canEditPolicy && window.location.pathname.includes('endorsement'))
                    this.onChangePolicy();
            }
        );
    };

    getSearchFilterValues = (transactionData, keyword) => {
        const lowerCaseFilterValue = keyword.toLocaleLowerCase();
        return _.filter(transactionData, (res) => {
            return Object.keys(res).some(
                (key) => typeof res[key] === 'string'
                    && res[key].toLocaleLowerCase().includes(lowerCaseFilterValue)
            );
        });
    };

    getJobNumberLink = (item, index, property) => {
        if (canUserOpenJobInXCenter(item)) {
            if (this.showOpenJobInXCenter(item)) {
                return (
                    <Link
                        href="/"
                        onClick={() => JobUtil.openJobInXCenter(item.jobNumber)}
                        className={styles.jobNumberColumnLink}
                    >
                        {item[property.id]}
                    </Link>
                );
            }
            return (
                <Link to={JobUtil.getJobDetailURLByJobType(item.type, item.jobNumber)} className={styles.jobNumberColumnLink}>
                    {item[property.id]}
                </Link>
            );
        }
        return <span>{item.jobNumber}</span>;
    };

    showOpenJobInXCenter = (item) => {
        return (
            item.type !== 'Submission'
            && item.type !== 'PolicyChange'
            && item.type !== 'Cancellation'
            && item.type !== 'Renewal'
        );
    };

    onChangePolicy = async () => {
        const { policyData } = this.state;
        const { fromAccountLanding, history } = this.props;
        const policyNumber = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.policyNumber'
        );
        const { lobEndorsementURL, endorsementProducts } = appConfig;
        const productCode = _.get(policyData, 'product.productCode');
        if (endorsementProducts.includes(productCode)) {
            history.push(lobEndorsementURL[productCode], { policyNumber });
        } else {
            this.setState({ disableChangePolicyLink: false });
        }
    };

    getJobNumber = async (policyNumber) => {
        const { authHeader } = this.props;
        const renewalVM = await PolicyService.createNewPolicyRenewalTransaction(
            [policyNumber],
            authHeader
        );
        return _.get(renewalVM, 'jobNumber');
    };

    onRenewalPolicy = async () => {
        const { fromAccountLanding, history } = this.props;
        const { lobRenewalURL } = appConfig;
        const policy = _.get(fromAccountLanding, 'policyDetailsData.policyResponse');
        const policyNumber = _.get(policy, 'policyNumber');
        const lobCode = _.get(policy, 'latestPeriod.policyLines[0].lineOfBusinessCode');
        const jobNumber = await this.getJobNumber(policyNumber);
        if (!_.isEmpty(lobRenewalURL[lobCode])) {
            history.push(lobRenewalURL[lobCode], { jobNumber });
        } else {
            JobUtil.openJobInXCenter(jobNumber);
        }
    };

    handleCancelButtonClick = () => {
        this.setState({ disableCancelLink: false });
    };

    onDoNotCancel = () => {
        this.setState({ disableCancelLink: true });
    };

    onDoNotChangePolicy = () => {
        this.setState({ disableChangePolicyLink: true });
    };

    // getResponse = async () => {
    //     const result = await PolicyService.getPolicy();
    //     return result;
    // };

    getHeaderSection = (title) => {
        return (isOpen) => (
            <React.Fragment>
                <Chevron isOpen={isOpen} />
                <h2 className={`${styles.title} ${styles.gwOverrideDefaultMargin}`}>{title}</h2>
            </React.Fragment>
        );
    };

    isGeneralCoverage = () => {
        const { policyData } = this.state;
        return (
            [
                'PersonalAuto',
                'Homeowners',
                'HOPHomeowners',
                'homeowners',
                'HomeownersLine_HOE',
                'PersonalVehicle_PV',
                'CommercialVehicle_PV'
            ].indexOf(policyData.product.productCode) === -1
        );
    };

    getPolicyTransactionStatus = () => {
        return [
            {
                code: 'all',
                name: gatewayMessages.all
            },
            {
                code: 'Draft',
                name: {
                    id: 'typekey.PolicyPeriodStatus.Draft',
                    defaultMessage: 'Draft'
                }
            }
        ];
    };

    handleFilterValueChange = (value) => {
        const { transactionResponse, searchTransactionKeyword } = this.state;
        this.setState({ selectedTransactionStatus: value });
        this.getMappedTransactionData(transactionResponse, {
            selectedTransactionStatus: value,
            searchTransactionKeyword
        });
    };

    handleSearchValueChange = (value) => {
        const { transactionResponse, selectedTransactionStatus } = this.state;
        this.setState({ searchTransactionKeyword: value });
        this.getMappedTransactionData(transactionResponse, {
            searchTransactionKeyword: value,
            selectedTransactionStatus
        });
    };

    handlePolicyDateFilter = (value) => {
        this.setState({ selectedTransactionDate: value });
        this.updatePolicyData(value);
    };

    translateUWIssueStatus = (item) => {
        const translator = this.context;
        if (!item.uwIssueStatus_PV) {
            return '-';
        }
        return translator({
            id: `gateway.directives.job-summary.${item.uwIssueStatus_PV}`,
            defaultMessage: item.uwIssueStatus_PV
        });
    };

    generateOverrides = () => {
        const { policyData } = this.state;
        const overrides = policyData.coverables.map((coverable, i) => {
            return {
                [`namesGrid${i}`]: {
                    visible: !_.isEmpty(coverable.coverages)
                }
            };
        });

        return Object.assign({}, ...overrides);
    };

    formatDate = (date) => {
        const d = new Date(date);
        return {year: d.getFullYear(), month: d.getMonth(), day: d.getDate()};
    };

    getPaymentPlanTranslation = (paymentMethod) => {
        const translator = this.context;
        const { viewModelService } = this.props;
        if (paymentMethod) {
            const paymentPlan = viewModelService.productMetadata.get('pc').types.getTypelist('BillingPeriodicity').getCode(paymentMethod);
            return translator({
                id: paymentPlan.name,
                defaultMessage: paymentPlan.code
            });
        }
        return '-';
    };

    getPaymentMethodTranslation = (paymentMethod) => {
        const translator = this.context;
        const { viewModelService } = this.props;
        if (paymentMethod) {
            const paymentPlan = viewModelService.productMetadata.get('pc').types.getTypelist('PolicyPaymentMethod_CxPV').getCode(paymentMethod);
            return translator({
                id: paymentPlan.name,
                defaultMessage: paymentPlan.code
            });
        }
        return '-';
    };

    handleClickParameters = () => {
        this.setState({ showModal: true });
    };

    closeParametersModal = () => {
        this.setState({ showModal: false });
    };

    render() {
        const {
            policyData,
            disableCancelLink,
            policyTransactionData,
            policyTransactionStatusValues,
            selectedTransactionStatus,
            searchTransactionKeyword,
            disableChangePolicyLink,
            canAdvanceCancellation,
            canEditPolicy,
            selectedTransactionDate,
            showModal,
            submissionVM
        } = this.state;
        const { authHeader, history, fromAccountLanding } = this.props;
        const policyNumber = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.policyNumber'
        );
        const productCode = _.get(policyData, 'product.productCode');
        if (_.isEmpty(policyData)) {
            return null;
        }
        const translator = this.context;
        const preRenewalDirection = _.get(
            fromAccountLanding,
            'policyDetailsData.policyResponse.latestPeriod.preRenewalDirection'
        );
        const overrides = {
            summaryPolicyTransactionsTableGrid: {
                data: policyTransactionData
            },
            coveragesSectionContainer: {
                visible: this.isGeneralCoverage()
            },
            coveragesSection: {
                renderHeader: this.getHeaderSection(translator(messages.Coverages))
            },
            cancellationContacts: {
                visible: !disableCancelLink && disableChangePolicyLink,
                history,
                authHeader,
                policyNumber: policyNumber,
                policyData: _.get(fromAccountLanding, 'policyDetailsData.policyResponse')
            },
            changePolicyContacts: {
                visible: !disableChangePolicyLink && disableCancelLink,
                history,
                authHeader,
                policyNumber: policyNumber,
                policyData: _.get(fromAccountLanding, 'policyDetailsData.policyResponse')
            },
            changePolicyButtonId: {
                disabled: !disableCancelLink || !disableChangePolicyLink,
                visible: !policyData.latestPeriod.canceled && policyData.latestPeriod.canChange && canEditPolicy
            },
            renewPolicyButtonId: {
                disabled: !disableCancelLink || !disableChangePolicyLink,
                visible: false // !policyData.latestPeriod.canceled && policyData.latestPeriod.canRenew
            },
            DeclarationPageId: {
                visible: false
            },
            IdCardButtonId: {
                visible: false
            },
            cancelPolicyButtonId: {
                disabled: !disableCancelLink || !disableChangePolicyLink,
                visible:
                    !policyData.latestPeriod.canceled
                    && policyData.latestPeriod.canCancel
                    && canAdvanceCancellation
            },
            policyVehicleInfo: {
                visible:
                    policyData.product.productCode === 'PersonalVehicle_PV' ||
                    policyData.product.productCode === 'CommercialVehicle_PV',
                value: policyData.lobs.ppvPersonalAuto_PV || policyData.lobs.pcvCommercialAuto_PV,
                productCode: policyData.product.productCode
            },
            policyHouseOwnersInfo: {
                visible:
                    productCode === 'Homeowners'
                    || productCode === 'HOPHomeowners'
                    || productCode === 'homeowners'
                    || productCode === 'HomeownersLine_HOE',
                value: policyData.lobs.homeOwners
            },
            policyTransactionStatus: {
                availableValues: policyTransactionStatusValues,
                value: selectedTransactionStatus
            },
            policyAsOfDate: {
                value: selectedTransactionDate,
                label: `${translator(messages.viewContractAsOf)}:`
            },
            searchFilter: {
                value: searchTransactionKeyword
            },
            preRenewalDirectionValueId: {
                value: !_.isNil(
                    preRenewalDirection
                ) ? [preRenewalDirection]
                    : translator(messages.SummaryDetailsPreRenewalDirectionNone)
            },
            producerOfRecordValueId: {
                value: `${_.get(
                    fromAccountLanding,
                    'policyDetailsData.policyResponse.latestPeriod.producerCodeOfRecordOrg'
                )}(${_.get(
                    fromAccountLanding,
                    'policyDetailsData.policyResponse.latestPeriod.producerCodeOfRecord'
                )})`
            },
            producerOfServiceValueId: {
                value: `${_.get(
                    fromAccountLanding,
                    'policyDetailsData.policyResponse.latestPeriod.producerCodeOfServiceOrg'
                )}(${_.get(
                    fromAccountLanding,
                    'policyDetailsData.policyResponse.latestPeriod.producerCodeOfService'
                )})`
            },
            copyContractCol: {
                policyNumber: policyNumber,
                buttonType: 'outlined'
            },
            detailGridPolicyOwnReferenceColumn: {
                visible: !_.isUndefined(_.get(policyData, 'latestPeriod.ownReference_PV'))
            },
            policyOwnReferenceValueId: {
                value: _.get(policyData, 'latestPeriod.ownReference_PV'),
                visible: !_.isUndefined(_.get(policyData, 'latestPeriod.ownReference_PV'))
            },
            policyInceptionValueId: {
                value: this.formatDate(_.get(policyData, 'latestPeriod.effectiveDate'))
            },
            policyExpirationValueId: {
                value: this.formatDate(_.get(policyData, 'latestPeriod.expirationDate'))
            },
            paymentPlan: {
                value: this.getPaymentPlanTranslation(_.get(policyData, 'latestPeriod.selectedPaymentPlan'))
            },
            activePaymentMethod: {
                value: this.getPaymentMethodTranslation(_.get(policyData, 'latestPeriod.selectedPaymentMethod'))
            },
            parametersLink: { visible: !!submissionVM },
            packParametersModal: {
                visible: showModal,
                submissionVM,
                readOnly: true
            },
            ...this.generateOverrides()
        };

        const resolvers = {
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                getCell: getCell,
                getJobNumberLink: this.getJobNumberLink,
                onChangePolicy: this.onChangePolicy,
                onRenewalPolicy: this.onRenewalPolicy,
                handleCancelButtonClick: this.handleCancelButtonClick,
                onDoNotCancel: this.onDoNotCancel,
                handleFilterValueChange: this.handleFilterValueChange,
                handlePolicyDateFilter: this.handlePolicyDateFilter,
                handleSearchValueChange: this.handleSearchValueChange,
                onDoNotChangePolicy: this.onDoNotChangePolicy,
                translateUWIssueStatus: this.translateUWIssueStatus,
                handleClickParameters: this.handleClickParameters,
                closeParametersModal: this.closeParametersModal,
                sortDate: DatatableUtil.sortDate,
                sortString: DatatableUtil.sortString,
                sortCurrency: DatatableUtil.sortCurrency,
                sortNumber: DatatableUtil.sortNumber
            },
            resolveComponentMap: {
                cancelcomponent: Cancellation,
                policypacomponent: PolicyVehiclesDriversInfo,
                changepolicycomponent: ChangePolicy,
                policydwellingcomponent: PolicyDwellingInfo,
                coveragesiterablecomponent: CoveragesIterableComponent,
                copycontractcomponent: CopyContractComponent
            }
        };
        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, policyData, id, path, overrides);
        };
        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyData}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
                resolveValue={readValue}
            />
        );
    }
}

export const SummaryComponent = Summary;
export default withViewModelService(withRouter(withIntl(withAuthenticationContext(withModalContext(Summary)))));
