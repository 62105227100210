/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useTranslator } from '@jutro/locale';
import { MetadataForm } from '@jutro/uiconfig';
import { useModal } from '@jutro/components';
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { PVVehicleLookupService } from 'pv-capability-policyjob';
import { messages as platformMessagesPV } from 'pv-platform-translations';
import { VehicleUtil } from 'pv-portals-util-js';
import VehicleType from "./VehicleType/VehicleType";
import VehicleConfirmationPopup from './VehicleConfirmationPopup/VehicleConfirmationPopup';
import VehicleRejectionPopup from './VehicleRejectionPopup/VehicleRejectionPopup';
import uiMetadata from "./VehicleLookup.metadata.json5";
import VehicleListPopup from './VehicleListPopup/VehicleListPopup';
import styles from "./VehicleLookup.module.scss";
import messages from './VehicleLookup.messages';

const VehicleLookup = ({
    data: vehicleVm,
    path: vehicleVmPath,

    setVehicleLookupData,
    setVehicleCategoryAndType,
    onValueChange,

    productCode,
    vehicles,
    virtualProduct,
    offerNumber
}) => {
    const { gatewayParamConfig: { enableLicensePlateSearch } } = appConfig;
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);

    const [inputs, setInputs] = useState({
        licensePlateInput: vehicleVm.license.value,

        vinInput: vehicleVm.vin.value,

        makeInput: null,
        modelInput: null,
        engineInput: null,
        fuelInput: null
    });

    const [licensePlateSearchErrors, setLicensePlateSearchErrors] = useState([]);
    const [vinSearchErrors, setVINSearchErrors] = useState([]);

    const [availableMake, setAvailableMake] = useState([]);
    const [availableModel, setAvailableModel] = useState([]);
    const [availableEngine, setAvailableEngine] = useState([]);
    const [availableFuel, setAvailableFuel] = useState([]);

    // single vehicle result
    const [foundVehicle, setFoundVehicle] = useState(null);

    // multi vehicle result
    const [foundMultiVehicles, setFoundMultiVehicles] = useState(null);
    const [cachedFoundMultiVehicles, setCachedFoundMultiVehicles] = useState(null);
    const [searchCriteria, setSearchCriteria] = useState(null);

    const { showAlert } = useModal();

    const handleLicensePlateAndVinInputChange = useCallback((newValue, path) => {
        const newValueFormated = newValue?.toUpperCase();
        setInputs((oldValues) => {
            const newValues = { ...oldValues };
            _.set(newValues, path, newValueFormated);
            return newValues;
        });
        if (path === 'vinInput') {
            onValueChange(newValue, `${vehicleVmPath}.vin`);
        }
        setLicensePlateSearchErrors([]);
        setVINSearchErrors([]);
    }, [vehicleVmPath, onValueChange]);

    const onVehicleTypeValueChange = useCallback((vehicleCategory, vehicleType) => {
        setVehicleCategoryAndType(vehicleVmPath, vehicleCategory, vehicleType);
    }, [setVehicleCategoryAndType, vehicleVmPath]);

    const handleTechnicalError = useCallback(() => {
        showAlert({
            title: platformMessagesPV.genericTechnicalErrorTitle,
            message: messages.informexNoConnectionError,
            status: 'error',
            icon: 'mi-error-outline'
        });
    }, [showAlert]);

    const handleInformexException = useCallback((errorCode, setErrors) => {
        if (errorCode === 'UnknowException') {
            handleTechnicalError();
        } else {
            const newError = translator({id: `quoteandbind.ppv.expection.informex.${errorCode}`}); // POR-2061
            setErrors((existingErrors) => existingErrors.concat(newError));
        }
    }, [translator, handleTechnicalError]);

    const onSearchByLicensePlate = useCallback(async () => {
        setLicensePlateSearchErrors([]);
        setVINSearchErrors([]);

        try {
            const vehicleInfo = await PVVehicleLookupService.autofillBasedOnLicensePlate(inputs.licensePlateInput, authHeader);

            if (vehicleInfo.errorCode) {
                const errorMessage = translator(messages.invalidLicense);
                setLicensePlateSearchErrors((existingErrors) => existingErrors.concat(errorMessage));
                handleInformexException(vehicleInfo.errorCode, setLicensePlateSearchErrors);
            } else {
                setFoundVehicle(vehicleInfo);
                // FoundVehicle will show VehicleDetailsPopup
            }
        } catch (exception) {
            handleTechnicalError();
        }
    }, [authHeader, handleInformexException, handleTechnicalError, translator, inputs]);

    const onSearchByVIN = useCallback(async () => {
        setVINSearchErrors([]);
        setLicensePlateSearchErrors([]);

        try {
            const vehicleInfo = await PVVehicleLookupService.autofillBasedOnVIN(inputs.vinInput, authHeader);

            if (vehicleInfo.errorCode) {
                const errorMessage = translator(messages.invalidVin);
                setVINSearchErrors((existingErrors) => existingErrors.concat(errorMessage));
                handleInformexException(vehicleInfo.errorCode, setVINSearchErrors);
            } else {
                setFoundVehicle(vehicleInfo);
                // FoundVehicle will show VehicleDetailsPopup
            }
        } catch (exception) {
            handleTechnicalError();
        }
    }, [authHeader, handleInformexException, handleTechnicalError, translator, inputs]);

    const labelforFuelType = useCallback((code, defaultLabel) => {
        switch (code) {
            case 'Diesel':
            case '2':
                return translator(messages.fuelTypeDiesel);
            case 'Petrol':
            case '1':
                return translator(messages.fuelTypePetrol);
            case 'LPG':
            case '3':
                return translator(messages.fuelTypeLPG);
            case 'Electric':
            case '4':
                return translator(messages.fuelTypeElectric);
            case 'Natural Gas':
            case '6':
                return translator(messages.fuelTypeNaturalGas);
            case 'Hybrid':
            case '7':
                return translator(messages.fuelTypeHybrid);
            default:
                // do not return UNKNOWN, still return human readable label
                return defaultLabel;
        }
    }, [translator]);

    const onMakeModelEngineFuelChange = useCallback(async (value, path) => {

        // NOTE: there is hardcoded order of the questions/asnwers in P&V Informex search.
        // Does not make sense to make q/a somehow dynamic.

        // ........INPUTS UPDATES...........
        let updatedInputs = inputs; // needed to work around async React state updates (e.g. setInputs doesn't update state immediately)
        if (path === 'makeInput') {
            if (value === 'ignore1' || value === 'ignore2') {
                // ignore title 'most popular vehicles' and 'all vehicles' selections ...
                // and abrupt whole execution.
                return null;
            };
            setInputs((oldValue) => {
                updatedInputs = { ...oldValue, makeInput: value, modelInput: null, engineInput: null, fuelInput: null };
                return updatedInputs;
            });
            setAvailableModel([]);
            setAvailableEngine([]);
            setAvailableFuel([]);
        } else if (path === 'modelInput') {
            setInputs((oldValue) => {
                updatedInputs = { ...oldValue, modelInput: value, engineInput: null, fuelInput: null };
                return updatedInputs;
            });
            setAvailableEngine([]);
            setAvailableFuel([]);
        } else if (path === 'engineInput') {
            setInputs((oldValue) => {
                updatedInputs = { ...oldValue, engineInput: value, fuelInput: null };
                return updatedInputs;
            });
            setAvailableFuel([]);
        } else if (path === 'fuelInput') {
            setInputs((oldValue) => {
                updatedInputs = { ...oldValue, fuelInput: value };
                return updatedInputs;
            });
        }

        // ........FORM SEARCH CRITERIA..........
        const UNKNOWN = 'UNKNOWN';
        const commonUnknown = {
            enginePower_PV: UNKNOWN,
            fuelType_PV: UNKNOWN,
            doors_PV: UNKNOWN,
            engineCO2_PV: UNKNOWN,
            engineCapacity_PV: UNKNOWN,
            firstRegistrationYear_PV: UNKNOWN,
            gearBox_PV: UNKNOWN,
            gearboxGears_PV: UNKNOWN,
            seats_PV: UNKNOWN,
            submodel_PV: UNKNOWN
        };
        let searchDto = {};
        if (updatedInputs.makeInput && updatedInputs.modelInput && updatedInputs.engineInput && updatedInputs.fuelInput) {
            searchDto = {
                ...commonUnknown,
                make: updatedInputs.makeInput,
                model: updatedInputs.modelInput,
                enginePower_PV: updatedInputs.engineInput,
                fuelType_PV: updatedInputs.fuelInput
            };
        } else if (updatedInputs.makeInput && updatedInputs.modelInput && updatedInputs.engineInput) {
            searchDto = {
                ...commonUnknown,
                make: updatedInputs.makeInput,
                model: updatedInputs.modelInput,
                enginePower_PV: updatedInputs.engineInput,
                fuelType_PV: null
            };
        } else if (updatedInputs.makeInput && updatedInputs.modelInput) {
            searchDto = {
                ...commonUnknown,
                make: updatedInputs.makeInput,
                model: updatedInputs.modelInput,
                enginePower_PV: null
            };
        } else if (updatedInputs.makeInput) {
            searchDto = {
                make: updatedInputs.makeInput,
                enginePower_PV: UNKNOWN
            };
        }

        setLicensePlateSearchErrors([]);
        setVINSearchErrors([]);
        setCachedFoundMultiVehicles(null);

        // ........DO SEARCH.........
        try {
            const result = await PVVehicleLookupService.autofillBasedOnPartialDto(searchDto, authHeader);


            // ........HANDLE SEARCH RESULTS.........
            if (result.nextQuestion_PV) {
                const choiceList = [];

                // generic filter:
                //  a) ignore UNKNOWN option
                //  b) transform to code/name list
                for (const propKey of Object.keys(result.vehicleData_PV)) {
                    if (propKey !== UNKNOWN) {
                        choiceList.push({
                            'code': propKey,
                            'name': result.nextQuestion_PV === 'ENGINE_FUEL' ? labelforFuelType(propKey, result.vehicleData_PV[propKey])
                                : result.vehicleData_PV[propKey]
                        });
                    }
                }


                if (result.nextQuestion_PV === 'VEHICLE_MANUFACTURER') {
                    // most popular makers go first ...
                    const mostPopularMakersTypelist = viewModelService.productMetadata.get('pc').types.getTypelist('MostPopularMakes_PV');
                    const choiceListWithPopularOntop = [];
                    choiceListWithPopularOntop.push({
                        code: 'ignore1',
                        name: translator(messages.mostPopularMakes, { b: (chunks) => <b>{chunks}</b> })
                    });
                    mostPopularMakersTypelist.codes.forEach((item) => {
                        choiceListWithPopularOntop.push({
                            code: item.code,
                            name: translator({
                                id: item.name,
                                defaultMessage: item.name
                            })
                        });
                    });
                    choiceListWithPopularOntop.push({
                        code: 'ignore2',
                        name: translator(messages.allMakes, { b: (chunks) => <b>{chunks}</b> })
                    });
                    choiceList.forEach((item) => {
                        choiceListWithPopularOntop.push(item);
                    });
                    setAvailableMake(choiceListWithPopularOntop);
                } else if (result.nextQuestion_PV === 'VEHICLE_MODEL') {
                    setAvailableModel(choiceList);
                } else if (result.nextQuestion_PV === 'ENGINE_POWER') {
                    setAvailableEngine(choiceList);
                } else if (result.nextQuestion_PV === 'ENGINE_FUEL') {
                    setAvailableFuel(choiceList);
                } else {
                    // ups, we ran into some new scenario ...
                    handleTechnicalError();
                }
            } else if (result.errorCode) {
                let title = null;
                let message = null;
                if (result.errorCode === 'InformexTooManyResultsException') {
                    title = translator(messages.tooManyResultsErrorTitle);
                    message = translator(messages.tooManyResultsErrorMsg);
                } else {
                    title = translator(messages.genericErrorTitle);
                    message = translator(messages.genericErrorMsg);
                }

                showAlert({
                    title,
                    message,
                    status: 'error',
                    icon: 'mi-error-outline'
                });
            } else if (_.isObject(result.vehicleData_PV) && Object.keys(result.vehicleData_PV).length === 1) {
                const selectedId = Object.keys(result.vehicleData_PV)[0];
                const vehicleInfo = await PVVehicleLookupService.autofillBasedOnVehicleId(selectedId);
                // FoundVehicle will show VehicleDetailsPopup
                setFoundVehicle(vehicleInfo);
            } else if (_.isObject(result.vehicleData_PV)) {
                setFoundMultiVehicles(result.vehicleData_PV);
                const searchCriteriaList = [];
                searchCriteriaList.push({
                    field: translator(messages.make),
                    value: availableMake.find((item) => item.code === updatedInputs.makeInput).name
                });
                searchCriteriaList.push({
                    field: translator(messages.model),
                    value: availableModel.find((item) => item.code === updatedInputs.modelInput).name
                });
                if (updatedInputs.engineInput) {
                    searchCriteriaList.push({
                        field: translator(messages.enginePower),
                        value: availableEngine.find((item) => item.code === updatedInputs.engineInput).name
                    });
                }
                if (updatedInputs.fuelInput) {
                    searchCriteriaList.push({
                        field: translator(messages.fuelType),
                        value: availableFuel.find((item) => item.code === updatedInputs.fuelInput).name
                    });
                }
                setSearchCriteria(searchCriteriaList);
            }
        } catch (exception) {
            handleTechnicalError();
        }
    }, [viewModelService, authHeader, handleTechnicalError, translator, labelforFuelType, availableMake, availableModel, availableEngine, availableFuel, inputs, showAlert]);


    const onChooseAnotherVehicle = useCallback(() => {
        setFoundVehicle(null);
        setCachedFoundMultiVehicles(foundMultiVehicles);
        setFoundMultiVehicles(null);
    }, [foundMultiVehicles]);

    const onConfirmVehicleDetails = useCallback(() => {
        setVehicleLookupData(vehicleVmPath, foundVehicle);
        setCachedFoundMultiVehicles(foundMultiVehicles);
        setFoundVehicle(null);
        setFoundMultiVehicles(null);
    }, [vehicleVmPath, setVehicleLookupData, foundVehicle, foundMultiVehicles]);

    const onSelectAnotherVehicle = useCallback(() => {
        setFoundMultiVehicles(cachedFoundMultiVehicles);
        setCachedFoundMultiVehicles(null);
    }, [cachedFoundMultiVehicles]);

    const onConfirmChoosenVehicleId = useCallback(async (vehicleId) => {
        try {
            const fullVehicleDetails = await PVVehicleLookupService.autofillBasedOnVehicleId(vehicleId, authHeader);
            setVehicleLookupData(vehicleVmPath, fullVehicleDetails);
        } catch (exception) {
            handleTechnicalError();
        }
        setCachedFoundMultiVehicles(foundMultiVehicles);
        setFoundMultiVehicles(null);
    }, [authHeader, handleTechnicalError, vehicleVmPath, setVehicleLookupData, foundMultiVehicles]);

    useEffect(() => {
        // runs empty search for Makers
        onMakeModelEngineFuelChange();
    }, []);

    const vehicleCategoryCode = _.get(vehicleVm, 'category.value.code');
    const vehicleTypeCode = _.get(vehicleVm, 'type.value.code');
    const isTourismAndBusiness = VehicleUtil.isTourismAndBusiness(vehicleCategoryCode);
    const isMobilHome = VehicleUtil.isMobilhomeVehicle(vehicleCategoryCode);
    const shouldShowRejection =  isTourismAndBusiness && foundVehicle?.maxAuthorizedMass_PV > 3500;

    const resolvers = {
        resolveCallbackMap: {
            onTriggerSearchVINButton: onSearchByVIN
        },
        resolveComponentMap: {
            VehicleType,
            VehicleConfirmationPopup,
            VehicleRejectionPopup,
            VehicleListPopup
        },
        resolveClassnameMap: styles
    };
    const overrideProps = {

        // vehicle type selector
        vehicleTypeSelector: {
            visible: _.isNil(vehicleCategoryCode) && _.isNil(vehicleTypeCode),

            productCode: productCode,
            vehicle: vehicleVm, // current vehicle
            vehicles: vehicles, // all vehicles (including current)
            virtualProduct: virtualProduct,
            offerNumber: offerNumber,

            vehicleCategoryValue: vehicleCategoryCode,
            vehicleTypeValue: vehicleTypeCode,
            onValueChange: onVehicleTypeValueChange
        },

        // whole search container
        searchContainer: {
            visible: !_.isNil(vehicleCategoryCode) && !_.isNil(vehicleTypeCode) && !isMobilHome
        },

        // LicensePlate search fields
        licensePlateSearchContainer: {
            visible: enableLicensePlateSearch
        },
        licensePlateInput: {
            value: inputs.licensePlateInput,
            onValueChange: handleLicensePlateAndVinInputChange
        },
        searchLicensePlateButton: {
            onTrigger: onSearchByLicensePlate
        },
        licensePlateSearchErrorsMsg: {
            visible: licensePlateSearchErrors.length > 0,
            content: licensePlateSearchErrors.join("\n")
        },

        // 1st OR
        or1: {
            visible: enableLicensePlateSearch
        },

        // VIN search fields
        vinInput: {
            value: inputs.vinInput,
            disabled: false,
            onValueChange: handleLicensePlateAndVinInputChange
        },
        searchVinButton: {
            disabled: !inputs.vinInput
        },
        vinSearchErrorsMsg: {
            visible: vinSearchErrors.length > 0,
            content: vinSearchErrors.join("\n")
        },

        // 2nd OR
        or2: {
            visible: isTourismAndBusiness
        },

        // make/mode search fields
        makeModelSearchContainer: {
            visible: isTourismAndBusiness
        },

        make: {
            value: inputs.makeInput,
            availableValues: availableMake,
            onValueChange: onMakeModelEngineFuelChange
        },
        model: {
            visible: !_.isEmpty(availableModel),
            value: inputs.modelInput,
            availableValues: availableModel,
            onValueChange: onMakeModelEngineFuelChange
        },
        enginePower: {
            visible: !_.isEmpty(availableEngine),
            value: inputs.engineInput,
            availableValues: availableEngine,
            onValueChange: onMakeModelEngineFuelChange
        },
        fuelType: {
            visible: !_.isEmpty(availableFuel),
            value: inputs.fuelInput,
            availableValues: availableFuel,
            onValueChange: onMakeModelEngineFuelChange
        },
        selectAnotherVehicle: {
            visible: !_.isEmpty(cachedFoundMultiVehicles),
            onClick: onSelectAnotherVehicle
        },

        // confirmation of a single vehicle
        vehicleConfirmationPopup: {
            visible: _.isObject(foundVehicle) && !shouldShowRejection,
            vehicleInfo: foundVehicle,
            onChooseAnotherVehicle,
            onConfirmVehicleDetails
        },
        // Reject of a single vehicle
        vehicleRejectionPopup: {
            visible: _.isObject(foundVehicle) && shouldShowRejection,
            isPersonalLine: productCode === 'PersonalVehicle_PV',
            onChooseAnotherVehicle
        },

        // confirmation of a selected vehicle out of multiple choices
        vehicleListPopup: {
            visible: !_.isEmpty(foundMultiVehicles),
            searchCriterias: searchCriteria,
            vehiclesInfo: foundMultiVehicles,
            onChooseAnotherVehicle,
            onConfirmChoosenVehicleId
        }
    };

    return (
        <MetadataForm
            uiProps={uiMetadata.pageContent}
            overrideProps={overrideProps}
            showErrors
            data={inputs}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassnameMap}
            isUsingNewValidation />
    );
};

const vehicleLookupPropTypes = {
    path: PropTypes.string,
    data: PropTypes.shape({}),
    getVehicleLookupData: PropTypes.func,
    setVehicleCategoryAndType: PropTypes.func,
    productCode: PropTypes.string,
    vehicles: PropTypes.array,
    virtualProduct: PropTypes.string,
    offerNumber: PropTypes.string,
};

VehicleLookup.propTypes = vehicleLookupPropTypes;

export default VehicleLookup;
