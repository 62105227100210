import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { MetadataContent } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { ActivitiesService } from 'gw-capability-gateway';
import OpenActivitiesComponent from '../../Components/OpenActivitiesComponent/OpenActivitiesComponent';
import metadata from './OpenActivities.metadata.json5';

class PolicyOpenActivities extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        authHeader: PropTypes.shape({}).isRequired,
        fromAccountLanding: PropTypes.shape({
            policyDetailsData: PropTypes.shape({
                policyResponse: PropTypes.shape({
                    policyNumber: PropTypes.string
                }),
                getPolicyDetails: PropTypes.func.isRequired,
                getUpdateCountInfo: PropTypes.func.isRequired
            }),
            basePath: PropTypes.string
        }).isRequired
    };

    getActivities = async () => {
        const {
            authHeader,
            fromAccountLanding: {
                policyDetailsData: { policyResponse }
            }
        } = this.props;
        const { policyNumber } = policyResponse;
        const getAvailableActivities = await ActivitiesService.getActivitiesForPolicy(
            policyNumber,
            authHeader
        );
        return getAvailableActivities;
    };

    createNewActivity = async (newActivityData) => {
        const { authHeader } = this.props;
        const addResponse = await ActivitiesService.createNewActivity(
            newActivityData,
            null,
            authHeader
        );

        return addResponse;
    };

    getActivitiesTileUpdatedCount = () => {
        const {
            fromAccountLanding: {
                policyDetailsData: { getUpdateCountInfo }
            }
        } = this.props;
        getUpdateCountInfo('OpenActivities');
    };

    getMetadataImplementation = () => {
        const {
            fromAccountLanding: {
                policyDetailsData: { policyResponse, getPolicyDetails }
            }
        } = this.props;
        const { policyNumber } = policyResponse;
        const overrides = {
            policyDetailsActivitiesContainer: {
                getActivities: this.getActivities,
                createNewActivity: this.createNewActivity,
                getPolicyDetails: getPolicyDetails,
                activityEntity: 'policy',
                activityEntityId: policyNumber,
                getActivitiesTileUpdatedCount: this.getActivitiesTileUpdatedCount
            }
        };
        const resolvers = {
            resolveComponentMap: {
                openactivitiescomponent: OpenActivitiesComponent
            }
        };
        return <MetadataContent uiProps={metadata.pageContent} overrideProps={overrides} {...resolvers} />;
    };

    render() {
        return this.getMetadataImplementation();
    }
}

export default withAuthenticationContext(PolicyOpenActivities);
