import React, { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import { ScrollToError } from '@jutro/wizard-next';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useTranslator } from '@jutro/locale';
import { SanitizerUtil, BusinessConstant } from 'pv-portals-util-js';
import { messages as platformMessagesPV } from 'pv-platform-translations';

import messageExt from 'gw-capability-gateway-react/NewQuote/NewQuoteAccountSearch.messages.js';
import metadata from './ContactPage.metadata.json5';
import styles from './ContactPage.module.scss';

function ContactPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const { onValidate, isComponentValid, initialValidation, registerInitialComponentValidation } = useValidation('ContactPage');
    const translator = useTranslator();
    const { sanitisePrefillDataFromQuickQuote } = SanitizerUtil;
    const [showErrors, setShowErrors] = useState(false);
    const [errorTimestamp, setErrorTimestamp] = useState(0);
    const [accountHolderPath] = useState('baseData.accountHolder');
    const [accountHolderVM, setAccountHolderVM] = useState(null);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateWizardData(newSubmissionVM);
            setAccountHolderVM(_.get(newSubmissionVM, accountHolderPath));
        },
        [viewModelService, submissionVM, updateWizardData, accountHolderPath]
    );

    useEffect(() => {
        const newSubmissionVM = viewModelService.clone(submissionVM);
        const accountHolderValue = _.get(newSubmissionVM.value, accountHolderPath);
        sanitisePrefillDataFromQuickQuote(accountHolderValue);
        _.set(newSubmissionVM, accountHolderPath, accountHolderValue);
        updateWizardData(newSubmissionVM);
        setAccountHolderVM(_.get(newSubmissionVM, accountHolderPath));
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const shouldPageSkip = useCallback(() => {
        return _.get(submissionVM, `${accountHolderPath}.hasDummyContextExchangeData_PV`) === false;
    }, [submissionVM, accountHolderPath]);

    useEffect(() => {
        registerInitialComponentValidation(shouldPageSkip);
    }, [registerInitialComponentValidation, shouldPageSkip]);


    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setShowErrors(true);
            setErrorTimestamp(Date.now());
            return false;
        }
        return submissionVM;
    }, [isComponentValid, submissionVM]);

    const generateOverrides = useCallback(() => {
        const overrideProps = {};

        const contactType = _.get(accountHolderVM, 'subtype.value');
        let contactTypeValue = '';
        if (contactType === "Person") {
            contactTypeValue = translator(messageExt.personal);
        }
        if (contactType === "Company") {
            contactTypeValue = translator(messageExt.commercial);
        }
        overrideProps.contactType = {
            value: contactTypeValue,
            readOnly: true
        };
        overrideProps.personContainer = {
            visible: contactType === 'Person'
        };
        overrideProps.commercialContainer = {
            visible: contactType === 'Company'
        };
        return overrideProps;
    }, [accountHolderVM, translator]);

    const primaryLanguageValues = useMemo(() => {
        const typelist = viewModelService.productMetadata.get('pc').types.getTypelist(BusinessConstant.LANGUAGE_TYPE);
        const codes = typelist.getFilter(BusinessConstant.DUTCH_AND_FRENCH_ONLY).codes;
        return codes.map((code) => ({
            code: code.code,
            name: {
                id: code.name,
                defaultValue: code.code
            }
        }));
    }, [viewModelService.productMetadata]
    );

    const overrideProps = {
        '@field': {
            showRequired: true,
            labelPosition: 'left',
            phoneWide: {
                labelPosition: 'top'
            }
        },
        genderRadioButton: {
            value: _.get(accountHolderVM, 'gender.value.code')
        },
        languageRadioButton: {
            value: _.get(accountHolderVM, 'primaryLanguage_PV.value.code'),
            availableValues: primaryLanguageValues
        },
        enterpriseNumberCompany: {
            validationMessages: _.get(submissionVM, 'baseData.accountHolder.enterpriseNumber_PV.value') === "0000000000" ? [platformMessagesPV.enterpriseError] : _.get(submissionVM, `baseData.accountHolder.enterpriseNumber_PV.aspects.validationMessages`)
        },
        ...generateOverrides()
    };
    
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            skipWhen={initialValidation}
            showPrevious={false}
            showCancel={false}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                showErrors={showErrors}
                model={accountHolderVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
            <ScrollToError counter={errorTimestamp} timeout={200} />
        </WizardPage>
    );
}

ContactPage.propTypes = wizardProps;
export default ContactPage;
