import { EntityUtil } from '@xengage/gw-portals-util-js';
import _ from 'lodash';
import { ServiceManager } from '@jutro/services';
import CustomClassValidations from './CustomClassValidations';


function createVehicleDriverForVehicle(coverables, vehicle) {
    if (vehicle) {
        coverables.drivers.forEach(function (d) {
            const vehicleDriver = {
                vehicleID: vehicle.fixedId,
                vehicleTempID: vehicle.tempID,
                driverID: d.fixedId,
                driverTempID: d.tempID,
                vehicleDriverUsage: 'NDR'
            };
            this.vehicleDrivers.push(vehicleDriver);
        }, coverables);
    }
};

function createVehicleDriverForDriver(coverables, driver) {
    if (driver) {
        coverables.vehicles.forEach(function (v) {
            const vehicleDriver = {
                vehicleID: v.fixedId,
                vehicleTempID: v.tempID,
                driverID: driver.fixedId,
                driverTempID: driver.tempID,
                vehicleDriverUsage: 'NDR'
            };
            this.vehicleDrivers.push(vehicleDriver);
        }, coverables);
    }
};

function createVehicle(coverables) {
    const v = {
        tempID: EntityUtil.nextId(),
        vehicleNumber: (_.maxBy(coverables.vehicles, (vehicle) => vehicle.vehicleNumber)?.vehicleNumber ?? 0) + 1,
    };
    createVehicleDriverForVehicle(coverables, v);
    coverables.vehicles.push(v);
    return v;
};

function removeVehicle(coverables, vehicle) {
    const idx = _.indexOf(coverables.vehicles, vehicle);
    if (idx >= 0) {
        coverables.vehicles.splice(idx, 1);

        if (_.isArray(coverables.vehicleDrivers)) {
            coverables.vehicleDrivers = coverables.vehicleDrivers.filter(
                (vd) => (!_.isNil(vd.vehicleID) && vd.vehicleID !== vehicle.fixedId) ||
                        (!_.isNil(vd.vehicleTempID) && vd.vehicleTempID !== vehicle.tempID)
            );
        }
    }
};


function findVehicleDriver(coverables, vehicle, driver) {
    return coverables.vehicleDrivers.find((vehicleDriver) => {
        // assume vehicle are saved and has fixedID from DB
        if (vehicleDriver.vehicleID === vehicle.fixedId) {
            const driverId = driver.fixedId ? driver.fixedId : driver.tempID;
            const vehicleDriverId = vehicleDriver.driverID ? vehicleDriver.driverID : vehicleDriver.driverTempID;
            return vehicleDriverId === driverId;
        }
        return false;
    });
};

function bindUsageAndDriverToVehicle(coverables, vehicle, driver, primaryDriver) {
    const vehicleDriver = findVehicleDriver(coverables, vehicle, driver);
    const driverUsage = primaryDriver ? "PRI" : "NDR";
    if (_.isObject(vehicleDriver)) {
        vehicleDriver.vehicleDriverUsage = driverUsage;
        if (CustomClassValidations.isRequiredDateFirstLicenseBForDriver(coverables.vehicles, coverables.vehicleDrivers, driver)) {
            driver.person.hasDrivingLicenseB_PV = true;
        }
    }
    if (primaryDriver) {
        const driverId = driver.fixedId ? driver.fixedId : driver.tempID;
        coverables.vehicleDrivers.forEach((vehDriver) => {
            const vehicleDriverId = vehDriver.driverID ? vehDriver.driverID : vehDriver.driverTempID;
            if (vehicleDriver && vehicleDriver.vehicleID === vehicle.fixedId) {
                if (vehicleDriverId !== driverId && vehDriver.vehicleDriverUsage === "PRI") {
                    // if somebody was a PRI driver, well, will be no more !
                    vehDriver.vehicleDriverUsage = "NDR";
                }
            }
        });
    }
};

function setDriverUsageForVehicles(coverables, driver, isPrimaryDriver) {

    if (isPrimaryDriver)  {
        const thisDriverId = driver.fixedId ? driver.fixedId : driver.tempID;
        // if this driver is PRIMARY, then all the rest NON-PRIMARY
        coverables.drivers.forEach((eachDriver) => {
            const eachDriverId = driver.fixedId ? driver.fixedId : driver.tempID;
            if (eachDriverId !== thisDriverId) {
                eachDriver.mainDriver = false; // SEC or NDR
            }
        });
    }
    // kind of duplicated information on Driver itself
    // this field facilitates validations of other fields
    driver.mainDriver = isPrimaryDriver;

    coverables.vehicles.forEach((vehicle) => {
        bindUsageAndDriverToVehicle(coverables, vehicle, driver, isPrimaryDriver);
    });
};

function updateVehicleToNonMainDriverRelation(coverables, vehicle, driver, isAssigned) {
    const vehicleDriver = findVehicleDriver(coverables, vehicle, driver);
    if (vehicleDriver) {
        if (isAssigned) {
            // non-main driver
            vehicleDriver.vehicleDriverUsage = 'SEC';
            if (CustomClassValidations.isRequiredDateFirstLicenseBForDriver(coverables.vehicles, coverables.vehicleDrivers, driver)) {
                driver.person.hasDrivingLicenseB_PV = true;
            }
        } else {
            // non driver
            vehicleDriver.vehicleDriverUsage = 'NDR';
            if (!CustomClassValidations.isRequiredDateFirstLicenseAForDriver(coverables.vehicles, coverables.vehicleDrivers, driver)) {
                driver.person.dateFirstLicenseA_PV = null;
            }
        }
    }
};

function isVehicleAssignedToDriver(coverables, vehicle, driver) {
    const vehicleDriver = findVehicleDriver(coverables, vehicle, driver);
    if (vehicleDriver) {
        return vehicleDriver.vehicleDriverUsage !== 'NDR';
    }
    return false;
};

function defaultBooleanToIfNotSet (propertyValue, defaultValue) {
    if (!_.isBoolean(propertyValue)) {
        return defaultValue;
    }
    return propertyValue;
};

function createPerson(options) {
    const localeService = ServiceManager.getService('locale-service');
    const person = {
        tempID: EntityUtil.nextId(),
        claimAttest_PV: true, // NOTE: mysterious field from DTO, never used, but mandatory
        primaryAddress: {
            country: localeService.getDefaultCountryCode(),
            addressType: 'home'
        }
    };

    _.extend(person, options);
    person.driverCommitedOffense_PV = defaultBooleanToIfNotSet(person.driverCommitedOffense_PV,false);
    person.licenceWithdrawel_PV = defaultBooleanToIfNotSet(person.licenceWithdrawel_PV,false);
    return person;
};

function getNextDriverSeqNumber(coverables) {
    const lastDriverBySeqNumber = _.maxBy(coverables.drivers, (driver) => driver.seqNumber);
    if (lastDriverBySeqNumber && lastDriverBySeqNumber.seqNumber) {
        return lastDriverBySeqNumber.seqNumber + 1;
    }
    return 1;
};

function createDriver(coverables, person) {
    const driver = {
        person,
        isPolicyHolder: _.get(person, 'accountHolder', false) // copy to Driver.isPolicyHolder from AccountContact.accountHolder
    };

    driver.medicalDisability_PV = defaultBooleanToIfNotSet(driver.medicalDisability_PV,false);
    driver.seqNumber = getNextDriverSeqNumber(coverables);
    driver.tempID = EntityUtil.nextId();
    driver.dateFirstLicensed_PV = driver.person.dateFirstLicensed_PV;
    createVehicleDriverForDriver(coverables, driver);
    coverables.drivers.push(driver);
    return driver;
};

function removeDriver(coverables, driver) {
    const idx = _.indexOf(coverables.drivers, driver);
    if (idx >= 0) {
        coverables.drivers.splice(idx, 1);

        for (let index = coverables.vehicleDrivers.length - 1; index >= 0; index--) {
            const vehicleDriver = coverables.vehicleDrivers[index];
            const isDriving = (!_.isNil(vehicleDriver.driverID) && vehicleDriver.driverID === driver.fixedId) ||
                (!_.isNil(vehicleDriver.driverTempID) && vehicleDriver.driverTempID === driver.tempID);

            if (isDriving) {
                coverables.vehicleDrivers.splice(index, 1);
            }
        }
    }
};

function tryAssignPrimaryDriver(coverables, vehicle) {
    const primaryDriver = _.find(coverables.vehicleDrivers, (vd) => vd.vehicleDriverUsage === 'PRI');
    if (_.isObject(primaryDriver)) {
        const thisVehicleAndPrimaryDriver = coverables.vehicleDrivers.find((vd) => vd.driverID === primaryDriver.driverID && vehicle.tempID === vd.vehicleTempID);
        if (_.isObject(thisVehicleAndPrimaryDriver)) {
            thisVehicleAndPrimaryDriver.vehicleDriverUsage = 'PRI';
        }
    }
}

function tryAssignNonMainDriverRelation(coverables, driver) {
    if (coverables.vehicles.length === 1) {
        const vehicle = coverables.vehicles[0];
        this.updateVehicleToNonMainDriverRelation(coverables, vehicle, driver, true /* assign SEC role */);
    }
}


export default {
    createDriver,
    removeDriver,
    createPerson,
    findVehicleDriver,
    createVehicle,
    removeVehicle,
    tryAssignPrimaryDriver,
    setDriverUsageForVehicles,
    updateVehicleToNonMainDriverRelation, // pre-upgrade bindVehicleToDriver()
    isVehicleAssignedToDriver, // pre-upgrade isVehicleSelected(),
    tryAssignNonMainDriverRelation
};
