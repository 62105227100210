import React from 'react';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ModalNext, ModalHeader, ModalBody, ModalFooter, Button } from '@jutro/components';
import metadata from './VehicleConfirmationPopup.metadata.json5';
import messages from './VehicleConfirmationPopup.messages';
import vehicleLookupMessages from '../VehicleLookup.messages';

function VehicleConfirmationPopup({
    vehicleInfo,
    onChooseAnotherVehicle,
    onConfirmVehicleDetails
}) {

    const translator = useTranslator();

    const titleMsg = translator(messages.vehicleDetails);
    const chooseAnotherMsg = translator(vehicleLookupMessages.chooseOtherVehicle);
    const confirmVehicleMsg = translator(vehicleLookupMessages.confirm);

    const translatedVehicleInfo = { ...vehicleInfo };
    if (translatedVehicleInfo.fuelType) {
        translatedVehicleInfo.fuelTypeName = translator({
            id: `typekey.PPVVehicleFuelType_PV.${vehicleInfo.fuelType}`,
            defaultMessage: vehicleInfo.fuelType
        });
    }

    if (translatedVehicleInfo.gearBox) {
        translatedVehicleInfo.gearBoxName = translator({
            id: `typekey.PPVVehicleGearBox_PV.${vehicleInfo.gearBox}`,
            defaultMessage: vehicleInfo.gearBox
        });
    }

    return (
        <ModalNext isOpen>
            <ModalHeader title={titleMsg} className="wizardPageHeader" onRequestClose={onChooseAnotherVehicle}/>
            <ModalBody>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={translatedVehicleInfo}
                />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onChooseAnotherVehicle} type="outlined">
                    {chooseAnotherMsg}
                </Button>
                <Button onClick={onConfirmVehicleDetails} type="outlined" className="buttonPrimary">
                    {confirmVehicleMsg}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

export default VehicleConfirmationPopup;
