import React, { useCallback, useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Link, Loader, useModal, FormattedDate
} from '@jutro/components';
import { withIntl, useTranslator } from '@jutro/locale';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { DatatableUtil } from '@xengage/gw-portals-util-js';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { PolicyService } from 'gw-capability-gateway';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import formatDocumentTableData from '../../util/DocumentUtil';
import metadata from './DocumentsComponent.metadata.json5';
import messages from './DocumentsComponent.messages';
import styles from './DocumentsComponent.module.scss';

const getDateModified = (items, index, { path: property }) => {
    // PV-22059: keeps raw date for sorting, use <FormattedDate> for converting and displaying
    return <FormattedDate value={items[property]} displayFormat='short' />;
};

const DocumentsComponent = (props) => {
    const {
        showConfirm,
        showAlert,
    } = useModal();

    const {
        authHeader,
        initialDocumentsData,
        showDocumentUpload,
        uploadDocument,
        deleteDocument,
        downloadDocument,
        noDataMessage,
        showPagination,
        showHeader,
        showSearch,
        isLoading,
        value,
        intl,
        documentTypeChange,
        docType,
        handleUploadClick,
        showDocDetails,
        policyNumber,
    } = props;
    const { UserService } = useDependencies('UserService');

    const [showDocumentUploadButton, updateShowDocumentUploadButton] = useState(false);
    const [showGenerateDocumentsButton, setShowGenerateDocumentsButton] = useState(false);
    const [showGenerateDocumentsSection, setShowGenerateDocumentsSection] = useState(false);
    const [searchKeyword, setKeyword] = useState('');

    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useTranslator();
    const { LoadSaveService } = useDependencies(['LoadSaveService']);
    const [currentPolicyDocument] = useState({
        code: 'policy_pv',
        name: translator(messages.generateDocumentsCurrentPolicy)
    });

    const documentTypeList = useMemo(() => {
        const docTypes = viewModelService.productMetadata.get('pc').types.getTypelist('DocumentType').codes;
        return docTypes.map((documentType) => ({
            code: documentType.code,
            name: translator({ id: documentType.name, defaultMessage: documentType.code })
        }));
    }, [translator, viewModelService.productMetadata]);

    useEffect(() => {
        if (showHeader) {
            const permissionDTO = {
                permission: 'doccreate'
            };
            UserService.hasUserSystemPermission(permissionDTO, authHeader)
                .then((isUserPermission) => {
                    updateShowDocumentUploadButton(isUserPermission);
                });
        }
        // Disabling to prevent continues re-rendering
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        policyNumber && PolicyService.autoRenewalPolicy(policyNumber, authHeader).then((resp) => setShowGenerateDocumentsButton(resp));
    }, [authHeader, policyNumber]);

    const getDownloadLink = useCallback(
        (item) => {
            const { publicID, sessionID, source_PV } = item;
            return downloadDocument(publicID, sessionID, source_PV);
        },
        [downloadDocument]
    );

    const onDeleteDocumentIcon = useCallback(
        (e, item) => {
            e.preventDefault();
            const { publicID } = item;
            showConfirm({
                title: messages.removeDocument,
                message: messages.confirmRemoveDocument,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: messages.documentRemoveConfirmYes,
                cancelButtonText: messages.documentRemoveConfirmNo
            }).then(async (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.noop();
                }
                deleteDocument(publicID);
                return true;
            }, _.noop);
        },
        [deleteDocument, showConfirm]
    );

    const getRemoveIcon = useCallback(
        (item, index, property) => {
            return (
                <Link
                    href={item[property.id]}
                    onClick={(e) => onDeleteDocumentIcon(e, item)}
                    className={styles.trashLink}
                    icon="mi-delete"
                />
            );
        },
        [onDeleteDocumentIcon]
    );

    const getNameLink = useCallback(
        (item, index, { path: property }) => {
            const downloadLink = getDownloadLink(item);
            return (
                <Link
                    icon="mi-insert_drive_file"
                    href={downloadLink}
                    target="_blank"
                    className={styles.documentFileNameColumnLink}
                >
                    {item[property]}
                </Link>
            );
        },
        [getDownloadLink]
    );

    const getDocType = useCallback(
        (item, index, { path: property }) => {
            if (item.name) {
                let documentType = '';
                const docTypesList = viewModelService.productMetadata.get(item.source_PV).types.getTypelist('DocumentType').codes;
                if (docTypesList) {
                    const docsType = docTypesList.find((document) => document.code === item[property]);
                    if (docsType) {
                        documentType = translator({ id: docsType.name });
                    }
                }
                return documentType;
            }
        }, [translator, viewModelService.productMetadata]
    );

    const handleSearchValueChange = useCallback((keyword) => {
        setKeyword(keyword);
    }, []);

    const handleGenerateDocumentsClick = useCallback(() => {
        setShowGenerateDocumentsSection((prev) => !prev);
    }, []);

    const generateDocument = useCallback(() => {
        LoadSaveService.generateDocument(policyNumber, currentPolicyDocument.code, authHeader)
            .catch(() => showAlert({
                title: commonMessages.genericError,
                message: commonMessages.genericErrorMessage,
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: translator(messages.createDocumentsClose)
            }));
        showAlert({
            status: 'info',
            icon: 'gw-info-outline',
            title: translator({
                id: 'gateway.directives.gateway-documents.Create Documents.Creation in progress',
                defaultMessage: 'Creation in progress'
            }),
            message: translator(messages.generateDocumentsCreationProgressMessage),
            confirmButtonText: translator(messages.createDocumentsClose)
        });
    }, [LoadSaveService, policyNumber, currentPolicyDocument.code, authHeader, showAlert, translator]);

    const overrideProps = {
        '@field': {
            showRequired: true,
        },
        generateDocumentsButton: {
            visible: showGenerateDocumentsButton,
        },
        tooltipGenerateDocumentsInfo: {
            visible: showGenerateDocumentsButton,
        },
        documentTableGrid: {
            data: formatDocumentTableData(intl, initialDocumentsData || value, searchKeyword),
            visible: !_.isEmpty(initialDocumentsData || value) && !isLoading,
            showPagination,
            showSearch
        },
        tilesPageHeaderSpan: {
            visible: (showDocumentUploadButton || showDocumentUpload)
        },
        noDocumentsDetails: {
            visible: !!noDataMessage && _.isEmpty(initialDocumentsData || value) && !isLoading
        },
        noDocumentsText: {
            message: noDataMessage
        },
        documentsTitleId: {
            visible: showHeader
        },
        uploadDocuments: {
            visible: !showHeader && showDocumentUpload
        },
        searchFilter: {
            visible: !_.isEmpty(initialDocumentsData || value),
            value: searchKeyword
        },
        generateDocumentsTitle: {
            visible: showGenerateDocumentsSection
        },
        genDocContainer: {
            visible: showGenerateDocumentsSection
        },
        genDocTypeSelect: {
            availableValues: [currentPolicyDocument],
            value: currentPolicyDocument
        },
        addDocumentTitle: {
            visible: showDocDetails
        },
        docContainer: {
            visible: showDocDetails
        },
        docTypeSelect: {
            availableValues: documentTypeList,
            value: docType
        },
        buttonContainer: {
            visible: docType !== null
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getNameLink: getNameLink,
            getDateModified: getDateModified,
            getRemoveIcon: getRemoveIcon,
            onUploadDocument: uploadDocument,
            handleSearchValueChange: handleSearchValueChange,
            onUploadClick: handleUploadClick,
            onGenerateDocumentsClick: handleGenerateDocumentsClick,
            onCreateDocumentsClick: generateDocument,
            sortDate: DatatableUtil.sortDate,
            documentTypeChange,
            getDocType,
            documentTypeList
        }
    };

    return (
        <Loader loaded={!isLoading}>
            <ViewModelForm
                uiProps={metadata.componentContent}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </Loader>
    );
};

DocumentsComponent.propTypes = {
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
    initialDocumentsData: PropTypes.shape({}),
    value: PropTypes.shape({}),
    uploadDocument: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    downloadDocument: PropTypes.func.isRequired,
    noDataMessage: PropTypes.string,
    showPagination: PropTypes.bool,
    showHeader: PropTypes.bool,
    showSearch: PropTypes.bool,
    showDocumentUpload: PropTypes.bool,
    isLoading: PropTypes.bool,
    intl: PropTypes.func.isRequired,
    documentTypeChange: PropTypes.func.isRequired,
    docType: PropTypes.string,
    handleUploadClick: PropTypes.func.isRequired,
    showDocDetails: PropTypes.bool,
    policyNumber: PropTypes.string
};

DocumentsComponent.defaultProps = {
    showPagination: true,
    noDataMessage: '',
    showHeader: true,
    showSearch: false,
    showDocumentUpload: false,
    isLoading: false,
    value: undefined,
    initialDocumentsData: undefined
};

export default withIntl(withAuthenticationContext(DocumentsComponent));
