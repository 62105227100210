import React, { useContext, useCallback, useEffect, useState, useMemo } from 'react';
import _ from 'lodash';

import { useModal } from '@jutro/components';
import { ScrollToError } from '@jutro/wizard-next';

import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import { PolicyContext } from 'pv-capability-policyjob-react';
import { BusinessConstant } from 'pv-portals-util-js';
import { messages as platformMessagesPV } from 'pv-platform-translations';
import ContractEffectiveDate from '../../components/ContractEffectiveDate/ContractEffectiveDate';
import messages from './ContractPage.messages';
import metadata from './ContractPage.metadata.json5';
import styles from './ContractPage.module.scss';

function ContractPage(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const policyContext = useContext(PolicyContext);

    const { setCanJumpPage, onJump, setOnJump } = policyContext;

    const { authHeader } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { onValidate: setComponentValidation , isComponentValid, initialValidation, registerInitialComponentValidation } = useValidation('ContractPage');

    const { wizardData: submissionVM, updateWizardData } = props;

    const isPPV = _.get(submissionVM, 'baseData.productCode.value') === 'PersonalVehicle_PV';
    const lobPath = isPPV ? 'ppvPersonalAuto_PV' : 'pcvCommercialAuto_PV';
    const accountHolder = _.get(submissionVM, 'baseData.accountHolder.value');
    const isAccountHolderACompany = accountHolder.subtype === 'Company';
    const customOfferingsBranchIdx = submissionVM.value.lobData[lobPath].offerings.findIndex((offering) => offering.branchCode === BusinessConstant.BRANCH_CODE_CUSTOM);
    const vehicleCoveragesPath = `lobData.${lobPath}.offerings.value[${customOfferingsBranchIdx !== -1 ? customOfferingsBranchIdx : 0}].coverages.vehicleCoverages`;
    const vehicleCoveragesInputPath = `lobData.${lobPath}.offerings.children[${customOfferingsBranchIdx !== -1 ? customOfferingsBranchIdx : 0}].coverages.vehicleCoverages.children`;
    const paymentDetailsPath = 'bindData.paymentDetails';
    const paymentMethodPath = `${paymentDetailsPath}.paymentMethod_PV`;
    const bankAccountDataPath = `${paymentDetailsPath}.bankAccountData`;

    const [showErrors, setShowErrors] = useState(false);
    const [errorTimestamp, setErrorTimestamp] = useState(0);
    const [isPaymentSEPA] = useState(_.get(submissionVM, `${paymentMethodPath}.value.code`) === 'sepa');

    const { showAlert, showConfirm } = useModal();

    /* P&V: `isCoverageDateDelayed` marks checked if a coverage's `effectiveDate_PV` is different than the policy's start date  */
    const isCoverageDateDelayed = useCallback((coverageType) => {
        const allCoveragesFromAllVehicles = _.reduce(
            _.get(submissionVM, vehicleCoveragesPath),
            (accum, vehicleCoverage) => accum.concat(vehicleCoverage.coverages),
            []
        );
        const coveragesOfCoverageType = _.filter(allCoveragesFromAllVehicles, ({selected, publicID}) => selected && publicID === coverageType);
        const isAnyDelayed = _.some(coveragesOfCoverageType,
            (coverage) => !_.isEqual(_.get(coverage, 'effectiveDate_PV'), _.get(submissionVM, 'baseData.periodStartDate.value'))
        );
        return isAnyDelayed;
    },
    [submissionVM, vehicleCoveragesPath]
    );

    const thirdPartyLiabilityCov = useMemo(() => {
        return isPPV ? BusinessConstant.PPV_THIRD_PARTY_LIABILITY_COV_PV : BusinessConstant.PCV_THIRD_PARTY_LIABILITY_COV_PV;
    }, [isPPV]);

    const legalProtectionCov = useMemo(() => {
        return isPPV ? BusinessConstant.PPV_LEGAL_PROTECTION_COV_PV : BusinessConstant.PCV_LEGAL_PROTECTION_COV_PV;
    }, [isPPV]);

    const [isTPLChecked, setIsTPLChecked] = useState(isCoverageDateDelayed(thirdPartyLiabilityCov));
    const [isLegalProtectionChecked, setIsLegalProtectionChecked] = useState(isCoverageDateDelayed(legalProtectionCov));

    useEffect(() => {
        if (
            _.get(submissionVM, 'isSubmitBroker.value') &&
            !_.isBoolean(_.get(submissionVM, 'baseData.accountHolder.emailUsageCommercial_PV.value'))
        ) {
            /* P&V: Added for `NE2E-2271`, value defaulted to `false` */
            _.set(submissionVM, 'baseData.accountHolder.emailUsageCommercial_PV.value', false);
        }

        const newSubmissionVM = viewModelService.changeContext(submissionVM, {
            DriverEmailRequired: false,
            ContractQuestions: true,
            AdditionalQuestions: false,
            SummaryQuestions: false
        });
        updateWizardData(newSubmissionVM);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, path, value);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    const areAllAllCoveragesDelayed = useMemo(() => {
        return !_.find(
            _.reduce(
                _.get(submissionVM, vehicleCoveragesPath),
                (accum, vehicleCoverage) => {
                    accum = accum.concat(vehicleCoverage.coverages);
                    return accum;
                },
                []
            ),
            (coverage) => _.isEqual(_.get(coverage, 'effectiveDate_PV'), _.get(submissionVM, 'baseData.periodStartDate.value'))
        );
    }, [submissionVM, vehicleCoveragesPath]);

    useEffect(() => {
        setCanJumpPage(!areAllAllCoveragesDelayed);
    }, [areAllAllCoveragesDelayed, setCanJumpPage]);

    const onStepJump = useCallback(() => {
        if (areAllAllCoveragesDelayed) {
            showAlert({
                title: messages.error,
                message: messages.minimumCoverageError,
                status: 'error',
                icon: 'mi-error-outline'
            });
            setOnJump(false);
            return false;
        }
        return true;
    }, [areAllAllCoveragesDelayed, setOnJump, showAlert]);

    useEffect(() => {
        if (onJump) {
            onStepJump();
        }
    }, [onJump, onStepJump]);

    const onPrevious = useCallback(async () => {
        if (!onStepJump()) {
            return false;
        }

        if (
            !_.some(
                _.get(submissionVM, 'underwritingIssues.value'),
                // eslint-disable-next-line camelcase
                ({ isEditAllowed_PV }) => !isEditAllowed_PV
            )
        ) {
            return submissionVM;
        }

        showConfirm({
            title: messages.areYouSure,
            message: messages.navigatingBack,
            status: 'warning',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.yesModel,
            cancelButtonText: commonMessages.noModel
        }).then((results) => {
            if (results === 'confirm') {
                return submissionVM;
            }
        });
    }, [onStepJump, submissionVM, showConfirm]);

    const updateMainDriverContactInfo = useCallback(() => {
        const accountHolderDriver = submissionVM.lobData[lobPath]?.coverables?.drivers.children.find(
            (d) => d.person.publicID.value === submissionVM.baseData.accountHolder.publicID.value
        );
        if (accountHolderDriver) {
            accountHolderDriver.person.emailAddress1 = submissionVM.baseData.accountHolder.emailAddress1;
            accountHolderDriver.person.shareEmailAddress_PV = submissionVM.baseData.accountHolder.shareEmailAddress_PV;
            accountHolderDriver.person.cellNumber = submissionVM.baseData.accountHolder.cellNumber;
            accountHolderDriver.person.primaryPhoneType = submissionVM.baseData.accountHolder.primaryPhoneType;
        }
    }, [lobPath, submissionVM]);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setShowErrors(true);
            setErrorTimestamp(Date.now());
            return false;
        }
        updateMainDriverContactInfo();
        _.set(submissionVM, `baseData.accountHolder.helpCancelCurrentPolicy_PV.value`, false);

        if (!onStepJump()) {
            return false;
        }

        try {
            if (isPaymentSEPA) {
                await LoadSaveService.saveSepaDetails(
                    _.get(submissionVM, 'sessionUUID.value'),
                    _.get(submissionVM, 'quoteID.value'),
                    _.get(submissionVM, `${bankAccountDataPath}.value`),
                    authHeader
                );
            }

            submissionVM.value = await LoadSaveService.updateContractQuestions(_.get(submissionVM, 'value'), authHeader);
        } catch (error) {
            showAlert({
                title: messages.error,
                message:
                    _.get(error, 'baseError').substring(_.get(error, 'baseError').lastIndexOf('ErrorMessage: ')) ||
                    platformMessagesPV.genericTimeoutErrorMsg,
                status: 'error',
                icon: 'mi-error-outline'
            });
            return false;
        }

        return submissionVM;
    }, [isComponentValid, submissionVM, onStepJump, isPaymentSEPA, LoadSaveService, authHeader, bankAccountDataPath, showAlert, updateMainDriverContactInfo]);

    const onEmailConsentChange = useCallback(
        (isConsentDeclined, path) => {
            _.set(submissionVM, path, isConsentDeclined);
            if (isConsentDeclined) {
                _.set(submissionVM, 'baseData.accountHolder.emailAddress1.value', '');
            }
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const onPhoneChange = useCallback(
        (phone, path) => {
            _.set(submissionVM, path, phone.trim());
            _.set(submissionVM, 'baseData.accountHolder.primaryPhoneType', phone.trim() ? 'mobile' : null);
            updateWizardData(submissionVM);
        },
        [submissionVM, updateWizardData]
    );

    const onWriteDelayDateValue = useCallback(
        (value, path, index) => {
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.set(newSubmissionVM, `${path}.coverages[${index}].effectiveDate_PV`, value);
            _.set(newSubmissionVM, `${path}.coverages[${index}].updated`, true);
            updateWizardData(newSubmissionVM);
        },
        [submissionVM, updateWizardData, viewModelService]
    );

    /* P&V: Updates all coverages dates except from TPL date, which is only updated when the checkbox `isTPLChecked` is not marked */
    const resetCoveragesDates = useCallback(
        (coverageType) => {

            // FYI: cannot use onWriteDelayDateValue() !
            // In case of multi-vehicle, updateWizardData() will be called several times,
            // but updateWizardData() supports only 1 call per 1 render cycle
            // (changes won't be summed, but will be overridden)
            const polStartDate = _.get(submissionVM, 'baseData.periodStartDate.value');
            const newSubmissionVM = viewModelService.clone(submissionVM);

            _.forEach(_.get(submissionVM, vehicleCoveragesPath), ({ coverages }, i) => {
                const covIndex = coverages.findIndex(({ publicID, selected }) => publicID === coverageType && selected);

                // cov must exists/be-selected in order to update it
                if (covIndex !== -1) {
                    const path = `${vehicleCoveragesPath}[${i}]`;
                    _.set(newSubmissionVM, `${path}.coverages[${covIndex}].effectiveDate_PV`, polStartDate);
                    _.set(newSubmissionVM, `${path}.coverages[${covIndex}].updated`, true);
                }
            });

            updateWizardData(newSubmissionVM);
        },
        [updateWizardData, submissionVM, vehicleCoveragesPath, viewModelService]
    );

    const onTPLChecked = useCallback(
        (isChecked) => {
            if (isTPLChecked === isChecked) {
                return;
            }

            setIsTPLChecked(isChecked);
            if (!isChecked) {
                resetCoveragesDates(thirdPartyLiabilityCov);
            }
        },
        [isTPLChecked, resetCoveragesDates, setIsTPLChecked, thirdPartyLiabilityCov]
    );

    const onLegalProtectionChecked = useCallback(
        (isChecked) => {
            if (isLegalProtectionChecked === isChecked) {
                return;
            }

            setIsLegalProtectionChecked(isChecked);
            if (!isChecked) {
                resetCoveragesDates(legalProtectionCov);
            }
        },
        [isLegalProtectionChecked, setIsLegalProtectionChecked, resetCoveragesDates, legalProtectionCov]
    );

    const generateCoverageOverrides = useCallback(
        (coverageType, coverageCode) => {
            const overrides = _.map(_.get(submissionVM, vehicleCoveragesPath), ({ vehicleName }, i) => {
                return {
                    [`${coverageCode}EffectiveDateComponent${i}`]: {
                        label: vehicleName,
                        vehicleCoveragesPath: vehicleCoveragesPath
                    }
                };
            });

            return Object.assign({}, ...overrides);
        },
        [submissionVM, vehicleCoveragesPath]
    );

    const shouldSkip = useCallback(() => {
        const isStartDayInTheFuture = (startingDay) => {
            if (!_.isEmpty(startingDay)) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                today.setDate(today.getDate() - BusinessConstant.MAXIMUM_PERIOD_START_DAY);
                const toCompare = new Date(startingDay.year, startingDay.month, startingDay.day);
                return today <= toCompare;
            }
            return false;
        };

        const { bankAccountData, paymentMethod_PV } = _.get(submissionVM.value, 'bindData.paymentDetails');
        const { isSubmitBroker, isSubmitAgent } = submissionVM.value;
        const { periodStartDate, periodStatus } = _.get(submissionVM.value, 'baseData');

        const isEmailSharingValid =
            isSubmitBroker ||
            (isSubmitAgent && (accountHolder.shareEmailAddress_PV || !_.isUndefined(accountHolder.emailUsageCommercial_PV)));
        const isPaymentMethodValid =
            paymentMethod_PV === 'bankTransfer' ||
            (paymentMethod_PV === 'sepa' &&
                !_.isEmpty(bankAccountData?.bankAccountNumber) &&
                (bankAccountData.belgianBank_PV ||
                    (!bankAccountData.belgianBank_PV && !_.isEmpty(bankAccountData?.bankABANumber))));

        return (
            periodStatus === 'Proposal_pv' ||
            periodStatus === 'Bound' ||
            (_.get(submissionVM, 'quoteData.portalPostQuote_PV.value') &&
                isEmailSharingValid &&
                isStartDayInTheFuture(periodStartDate) &&
                isPaymentMethodValid)
        );
    }, [accountHolder, submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(shouldSkip);
    }, [registerInitialComponentValidation, shouldSkip]);

    const overrideProps = {
        '@field': {
            showRequired: true,
            showOptional: false,
            labelPosition: 'left',
            phoneWide: {
                labelPosition: 'top'
            }
        },
        policyholderContactEmail: {
            disabled: _.get(submissionVM, 'baseData.accountHolder.shareEmailAddress_PV.value', false)
        },
        policyholderContactEmailConsent: {
            visible:
                _.get(submissionVM, 'baseData.brand_PV.value.code') === 'pv' &&
                !_.find(_.get(submissionVM, `lobData.${lobPath}.coverables.vehicles.value`), ({ ubi }) => ubi) &&
                _.isEmpty(_.trim(_.get(submissionVM, 'baseData.accountHolder.emailAddress1.value')))
        },
        policyholderContactEmailPromoConsent: {
            visible: !_.isEmpty(_.trim(_.get(submissionVM, 'baseData.accountHolder.emailAddress1.value')))
        },
        policyBeginningTPLDelay: {
            value: isTPLChecked
        },
        policyBeginningTPLDelayCoveragesListTitle: {
            visible: isTPLChecked
        },
        policyBeginningTPLDelayCoveragesList: {
            path: vehicleCoveragesInputPath,
            visible: isTPLChecked
        },
        policyBeginningLegalProtectionDelay: {
            value: isLegalProtectionChecked
        },
        policyBeginningLegalProtectionDelayCoveragesListTitle: {
            visible: isLegalProtectionChecked
        },
        policyBeginningLegalProtectionDelayCoveragesList: {
            path: vehicleCoveragesInputPath,
            visible: isLegalProtectionChecked
        },
        paymentBankAccount: {
            value: submissionVM,
            isPaymentSEPA
        },
        legalForm: {
            visible: isAccountHolderACompany
        },
        companyName: {
            visible: isAccountHolderACompany
        },
        enterpriseNumber: {
            visible: isAccountHolderACompany || !!accountHolder.deductVatForVehicle_PV,
            validationMessages: _.get(submissionVM, 'baseData.accountHolder.enterpriseNumber_PV.value') === "0000000000" ? [platformMessagesPV.enterpriseError] : _.get(submissionVM, `baseData.accountHolder.enterpriseNumber_PV.aspects.validationMessages`)
        },
        policyholderName: {
            visible: !isAccountHolderACompany
        },
        ...generateCoverageOverrides(thirdPartyLiabilityCov, 'tpl'),
        ...generateCoverageOverrides(legalProtectionCov, 'legalProtection')
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: setComponentValidation,
            onEmailConsentChange,
            onPhoneChange,
            onTPLChecked,
            onLegalProtectionChecked,
            onWriteDelayDateValue
        },
        resolveComponentMap: {
            effectiveDateComponent: ContractEffectiveDate
        }
    };

    return (
        <WizardPage onNext={onNext} onPrevious={onPrevious} showCancel={false} skipWhen={initialValidation}>
            <ViewModelForm
                uiProps={metadata.pageContent}
                showErrors={showErrors}
                model={submissionVM}
                overrideProps={overrideProps}
                onValueChange={writeValue}
                onValidationChange={setComponentValidation}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
            <ScrollToError counter={errorTimestamp} timeout={200} />
        </WizardPage>
    );
}

ContractPage.propTypes = wizardProps;
export default ContractPage;
