import { defineMessages } from 'react-intl';

export default defineMessages({
    atkLabel:{
        id:'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.ATK',
        defaultMessage:'ATK/DRQ'
    },
    brbLabel:{
        id:'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.BRB',
        defaultMessage:'BRB'
    },
    policyCancelledLabel: {
        id:'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.This policy has been cancelled',
        defaultMessage:'This policy has been cancelled / suspended.'
    },
    policyPeriodLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Policy Period',
        defaultMessage: 'Contract Period'
    },
    taxesFeesLabel: {
        id: 'gateway.views.policy-detail.Taxes and Fees',
        defaultMessage: 'Taxes and Fees'
    },
    totalCostsLabel: {
        id: 'gateway.views.policy-detail.Total Costs',
        defaultMessage: 'Total Costs'
    },
    statusLabel: {
        id: 'gateway.views.policy-detail.Status',
        defaultMessage: 'Status'
    },
    status: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Status',
        defaultMessage: 'Status'
    },
    delinquentLabel: {
        id: 'gateway.views.policy-detail.Delinquent',
        defaultMessage: 'Delinquent'
    },
    inGoodStandingLabel: {
        id: 'gateway.views.policy-detail.In Good Standing',
        defaultMessage: 'In Good Standing'
    },
    viewAccountBillingLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.View Account Billing',
        defaultMessage: 'View Account Billing'
    },
    customerPaymentSummaryLabel: {
        id: 'gateway.views.policy-detail.Customer Payment Summary',
        defaultMessage: 'Customer Payment Summary'
    },
    totalPastDueLabel: {
        id: 'gateway.views.policy-detail.Total Past Due',
        defaultMessage: 'Total Past Due'
    },
    nextPaymentLabel: {
        id: 'gateway.views.policy-detail.Next Payment',
        defaultMessage: 'Next Payment'
    },
    totalDueLabel: {
        id: 'gateway.views.policy-detail.Total Due',
        defaultMessage: 'Total Due'
    },
    billingMethodLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Billing Method',
        defaultMessage: 'Billing Method'
    },
    paymentPlanLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Payment Plan',
        defaultMessage: 'Payment Plan'
    },
    sendingChannelLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Sending Channel',
        defaultMessage: 'Sending Channel'
    },
    openInvoicesLable: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Open Invoices',
        defaultMessage: 'Open Invoices'
    },
    paymentScheduleLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Payment Schedule',
        defaultMessage: 'Payment Schedule'
    },
    disbursementInformationLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.There is no disbursement information for this policy.',
        defaultMessage: 'There is no disbursement information for this contract'
    },
    ibanLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.IBAN',
        defaultMessage: 'IBAN'
    },
    nameBeneficiaryLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Name Beneficiary',
        defaultMessage: 'Name Beneficiary'
    },
    referenceLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Reference',
        defaultMessage: 'Reference'
    },
    mandateReferenceLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Mandate reference',
        defaultMessage: 'Mandate reference'
    },
    mandateStatusLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Mandate Status',
        defaultMessage: 'Mandate Status'
    },
    paidTooltipLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.PaidTooltip',
        defaultMessage: 'In case of an online payment, the paid status will be updated to paid if the money is received.'
    },
    dueDateLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Due Date',
        defaultMessage: 'Due Date'
    },
    commissionLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Commission',
        defaultMessage: 'Commission'
    },
    paidStatusLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Paid Status',
        defaultMessage: 'Paid Status'
    },
    PaidLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Paid',
        defaultMessage: 'Paid'
    },
    outstandingLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Outstanding',
        defaultMessage: 'Outstanding'
    },
    statementAmountLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Statement Amount',
        defaultMessage: 'Statement Amount'
    },
    loadingLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Loading',
        defaultMessage: 'Loading...'
    },
    noBillingDataLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.No billing data for this policy, check back again in the next',
        defaultMessage: 'No billing data for this policy, check back again in the next 24 hour'
    },
    noInvoicePaymentorDisbursementLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.No open invoice, payment schedule or disbursement information data for this policy, please check back again in the next 24 hours',
        defaultMessage:'No open invoice, payment schedule or disbursement information data for this contract, please check back again in the next 24 hours.'
    },
    pastDue: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Past Due',
        defaultMessage: 'Past Due'
    },
    attentionLabel: {
        id: 'gateway.directives.PolicyBillingSummaryDirective.policy-billing-summary.Attention',
        defaultMessage:'Attention'
    },
    errorLoadingDisbursement: {
        id: 'gateway.views.account-detail.Error loading disbursements data',
        defaultMessage: 'Error loading disbursements data'
    }
});
