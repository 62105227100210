import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext, withIntl } from '@jutro/locale';
import { withValidation, validationPropTypes, validationDefaultProps } from '@xengage/gw-portals-validation-react';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import {BusinessConstant, EdgeErrorParser } from 'pv-portals-util-js';
import { ServiceManager } from '@jutro/services';
import { Link, withRouter } from 'react-router-dom';
import { withModalContext, Icon, Button } from '@jutro/components';
import { AccountService } from 'gw-capability-gateway-policy';
import { messages as platformMessagesPV } from 'pv-platform-translations';
import messages from './NewQuoteAccountSearch.messages';
import gatewayMessages from '../gateway.messages';
import styles from './NewQuoteAccountSearch.module.scss';
import metadata from './NewQuoteAccountSearch.metadata.json5';

const ACCOUNT_TYPE = {
    commercial: 'company',
    personal: 'person'
};

class NewQuoteAccountSearch extends Component {
    static contextType = TranslatorContext;

    state = {
        searchResult: false,
        submissionVM: {},
        existingSearchResult: [],
        showErrorContent: false,
        showNoMatch: false,
        showNoLengthMatch: false,
        showAccountMatches: false,
        showErrors: false,
        quoteType: undefined
    };

    static propTypes = {
        viewModelService: PropTypes.shape({
            create: PropTypes.func
        }).isRequired,
        authHeader: PropTypes.shape({}).isRequired,
        location: PropTypes.shape({
            state: PropTypes.shape({})
        }).isRequired,
        history: PropTypes.shape({
            push: PropTypes.func
        }).isRequired,
        intl: PropTypes.func.isRequired,
        ...validationPropTypes,
        ...validationDefaultProps
    };

    isQuickQuoteLanding = () => {
        return this.props.location.pathname.includes(BusinessConstant.QUICK_QUOTE_URL_SUFFIX);
    };

    showValidations = (value) => {
        let showContent = false;
        if (value.contactType === 'person') {
            if (value.firstName.length < 5 || value.lastName.length < 5) {
                showContent = true;
            }
        }
        return showContent;
    };

    isCompanyDataValid = (data) => {
        const { modalContext } = this.props;
        if (data.contactType !== ACCOUNT_TYPE.commercial) {
            return true;
        }
        const minimumValues = !_.isUndefined(data.enterpriseNumber_PV) || (!_.isUndefined(data.contactName) && !_.isUndefined(data.postalCode));
        if (!minimumValues) {
            modalContext.showAlert({
                title: gatewayMessages.modalError,
                message: messages.companyErrorMsg,
                status: 'error',
                icon: 'mi-error-outline'
            }).catch(_.noop);
            return false;
        }

        return true;
    };

    searchAccounts = async () => {
        const { submissionVM } = this.state;
        const translator = this.context;
        const {
            submissionVM: { value }
        } = this.state;
        const { authHeader, modalContext } = this.props;

        if (!this.isCompanyDataValid(value)) {
            return;
        }

        let { searchResult, existingSearchResult } = this.state;
        let accounts = [];
        try {
            const accountSearchData = {
                ...submissionVM.value,
                // eslint-disable-next-line no-warning-comments
                // TODO Viewmodel converting to PC DOB_PV => PE dob_pv
                dOB_PV: submissionVM.value.dob_pv
            };
            accounts = await AccountService.searchContactsAndAccounts(
                accountSearchData,
                authHeader
            );
            searchResult = true;
        } catch (error) {
            const msg = EdgeErrorParser.getErrorMessage(error);
            modalContext.showAlert({
                title: gatewayMessages.modalError,
                message: `${translator(gatewayMessages.gatewayViewsModalErrorData)}\n${msg}`,
                status: 'error',
                icon: 'mi-error-outline'
            });
        }
        existingSearchResult = this.getResponse(accounts);
        this.setState({
            searchResult,
            existingSearchResult
        });
        if (existingSearchResult.length === 0) {
            if (value.contactType === ACCOUNT_TYPE.commercial) {
                if (!value.contactName || value.contactName.length < 5) {
                    this.setState({
                        showNoLengthMatch: true,
                        showErrorContent: false,
                        showNoMatch: false,
                        showAccountMatches: false
                    });
                } else {
                    this.setState({
                        showNoMatch: true,
                        showErrorContent: false,
                        showNoLengthMatch: false,
                        showAccountMatches: false
                    });
                }
            } else if (this.showValidations(value) === true) {
                this.setState({
                    showErrorContent: true,
                    showNoMatch: false,
                    showNoLengthMatch: false,
                    showAccountMatches: false
                });
            } else {
                this.setState({
                    showNoMatch: true,
                    showErrorContent: false,
                    showNoLengthMatch: false,
                    showAccountMatches: false
                });
            }
        } else {
            this.setState({
                showAccountMatches: true,
                showNoMatch: false,
                showNoLengthMatch: false,
                showErrorContent: false
            });
        }
    };

    componentDidMount() {
        let { submissionVM } = this.state;
        const localeService = ServiceManager.getService('locale-service');
        const model = {
            contactType: ACCOUNT_TYPE.personal,
            country: localeService.getDefaultCountryCode()
        };
        submissionVM = this.createVM(model);
        const { location } = this.props;

        if (this.isQuickQuoteLanding()) {
            this.setState({quoteType: BusinessConstant.QUICK_QUOTE_URL_SUFFIX});
        };

        const quoteType = location?.state?.quoteType;
        this.setState({
            submissionVM,
            quoteType
        });
    };

    static getDerivedStateFromProps(props, state) {
        if (props?.location?.state?.quoteType !== state?.quoteType) {
            return {
                quoteType: props?.location?.state?.quoteType || props?.match?.params?.quoteType
            };
        }
        // Return null to indicate no change to state.
        return null;
    };

    createVM = (model) => {
        const { viewModelService } = this.props;
        return viewModelService.create(
            model,
            'pc',
            'edge.capabilities.gateway.account.search.dto.AccountSearchCriteriaDTO'
        );
    };

    writeValue = (value, path) => {
        const { submissionVM } = this.state;
        _.set(submissionVM, path, value);
        this.setState({ submissionVM });
    };

    onSearchAgain = () => {
        let { searchResult } = this.state;
        searchResult = false;
        this.setState({
            searchResult
        });
    };

    getCell = (item, index, { id: property }) => {
        const value = _.get(item, property);
        if (property === 'subtype') {
            const icon = value === 'Person' ? 'mi-person' : 'mi-business';
            return <Icon id={`icon-${value}`} icon={icon} />;
        }

        if (item.subtype === 'Person' && property.includes('dateOfBirth')) {
            const { intl } = this.props;
            return intl.formatDate(
                new Date(
                    value.year,
                    value.month,
                    value.day
                ), { year: 'numeric', month: 'short', day: 'numeric' }
            );
        }

        if (property === 'accountNumber') {
            const redirectPath = `/accounts/${value}/summary`;
            return (
                <Link to={redirectPath}>
                    {value}
                </Link>
            );
        }

        return value;
    };

    getNewQuoteButton = (item) => {
        const { quoteType } = this.state;
        const isQuickQuote = quoteType === BusinessConstant.QUICK_QUOTE_URL_SUFFIX;
        const translator = this.context;
        const { history } = this.props;
        const nextLocation = {
            bdpid: item.bdpid,
            publicId: item.publicID
        };

        const onNewBusinessClick = () => {
            history.push(`/new-quote/`, { ...nextLocation, quickQuote: false });
        };

        const onTariffClick = () => {
            history.push(`/new-quote/`, { ...nextLocation, quickQuote: true });
        };

        return (
            <div className={styles.newQuoteContainer}>
                {(isQuickQuote || !quoteType) && (
                    <Button id='tariffButton' onClick={onTariffClick}>
                        {translator(platformMessagesPV.tariff)}
                    </Button>
                )}
                {(!isQuickQuote || !quoteType) && (
                    <Button id='newQuoteButton' onClick={onNewBusinessClick}>
                        {translator(platformMessagesPV.newBusiness)}
                    </Button>
                )}
            </div>
        );
    };

    expandRow = (row) => {
        const resolvers = {
            resolveCallbackMap: {
                getCell: this.getCell
            }
        };
        const overrides = {
            headingForEmptyTable: {
                visible: _.isEmpty(row.contactAccounts)
            },
            heading: {
                visible: !_.isEmpty(row.contactAccounts)
            },
            accountsTable: {
                visible: !_.isEmpty(row.contactAccounts)
            }
        };
        return (
            <ViewModelForm
                uiProps={metadata.contactAccountsTable}
                overrideProps={overrides}
                model={row}
                callbackMap={resolvers.resolveCallbackMap}
            />
        );
    };

    getResponse = (searchResponse) => {
        const userList = searchResponse.map((result) => {

            // enriching account list with contact fields e.g. subtype
            let mappedAccounts = [];
            if (!_.isEmpty(result.contactAccounts)) {
                mappedAccounts = result.contactAccounts.map((item) => {
                    return {
                        ...item,
                        subtype: result.subtype,
                    };
                });
            }

            const user = {
                ...result,
                contactAccounts: mappedAccounts
            };

            return user;
        });
        return userList;
    };

    newCustomerBtnClick = () => {
        const { history } = this.props;
        const { submissionVM, quoteType } = this.state;
        const isQuickQuote = quoteType === BusinessConstant.QUICK_QUOTE_URL_SUFFIX;
        const nextLocation = {
            accountSearchCriteria: _.get(submissionVM, 'value'),
            quickQuote: isQuickQuote
        };
        history.push('/new-quote', nextLocation);
    };

    startNewQuoteLink = (item, index, { id: property }) => {
        const value = item[property];
        const { submissionVM } = this.state;
        const { accountNumber } = item;
        if (item.canView) {
            return (
                <Link
                    to={{
                        pathname: `/new-quote/${accountNumber}`,
                        state: {
                            accountSearchCriteria: submissionVM.value
                        }
                    }}
                >
                    {value}
                </Link>
            );
        }
        return <h4>{value}</h4>;
    };

    handleValidation = () => {
        this.setState({ showErrors: true });
    };

    handleNewQuoteCancel = () => {
        const { history } = this.props;
        history.push('/');
    };

    handleCancel = () => {
        const { modalContext } = this.props;
        modalContext.showConfirm({
            title: messages.cancelQuote,
            message: messages.cancelMessage,
            status: 'warning',
            icon: 'mi-error-outline'
        }).then((results) => {
            if (results === 'cancel' || results === 'close') {
                return _.noop();
            }
            return this.handleNewQuoteCancel();
        }, _.noop);
    };

    render() {
        const {
            submissionVM,
            searchResult,
            existingSearchResult,
            showErrorContent,
            showNoMatch,
            showNoLengthMatch,
            showAccountMatches,
            showErrors
        } = this.state;
        const { setComponentValidation, isComponentValid } = this.props;
        const accountType = _.get(submissionVM, `${'contactType'}.value.code`);
        const overrideProps = {
            '@field': {
                labelPosition: 'left',
                showRequired: true
            },
            accountTypeToggle: {
                value: accountType
            },
            commercialContainer: {
                visible: accountType === ACCOUNT_TYPE.commercial
            },
            personalContainer: {
                visible: accountType === ACCOUNT_TYPE.personal
            },
            dob: {
                visible: accountType === ACCOUNT_TYPE.personal
            },
            exisitingUserTable: {
                data: existingSearchResult
            },
            newQuoteContainer: {
                visible: !searchResult
            },
            existingSearchResult: {
                visible: searchResult
            },
            noMatch: {
                visible: showNoMatch
            },
//             detailsaccount: {
//                 visible: showAccountMatches
//             },
            possibleBaseQuote: {
                visible: showAccountMatches
            },
            noLengthMatch: {
                visible: showErrorContent
            },
            noCompanyLengthMatch: {
                visible: showNoLengthMatch
            },
            addressComponent: {
                value: submissionVM
            },
            possibleCancel: {
                visible: false
            },
            cancel: {
                visible: false
            },
            enterpriseNumber: {
                validationMessages: _.get(submissionVM, 'enterpriseNumber_PV.value') === "0000000000" ? [platformMessagesPV.enterpriseError] : _.get(submissionVM, `enterpriseNumber_PV.aspects.validationMessages`)
            },
        };

        const resolvers = {
            resolveValue: this.readValue,
            resolveClassNameMap: styles,
            resolveCallbackMap: {
                onSearchAccounts: isComponentValid
                    ? this.searchAccounts
                    : this.handleValidation,
                getCell: this.getCell,
                startNewQuoteLink: this.startNewQuoteLink,
                onSearchAgain: this.onSearchAgain,
                onCancel: this.handleCancel,
                newCustomerBtnClick: this.newCustomerBtnClick,
                onValidate: setComponentValidation,
                onExpandRow: this.expandRow,
                getNewQuoteButton: this.getNewQuoteButton
            }
        };

        const readValue = (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                onValidationChange={setComponentValidation}
                onValueChange={this.writeValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                showErrors={showErrors}
            />
        );
    }
}
export const NewQuoteAccountSearchComponent = withModalContext(NewQuoteAccountSearch);
export default withRouter(withIntl(withValidation(withViewModelService(withAuthenticationContext(withModalContext(NewQuoteAccountSearch))))));
