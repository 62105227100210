import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Link,
    ModalNext,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { MetadataContent } from '@jutro/uiconfig';
import { FormattedMessage, useTranslator } from '@jutro/locale';
import metadata from './CancellationReason.metadata.json5';
import gatewayMessages from '../../gateway.messages';

function CancellationReason(props) {
    const translator = useTranslator();
    const {
        title,
        actionBtnLabel,
        cancelBtnLabel,
        withDrawRenewal,
        reasons,
        isOpen,
        onResolve,
        onReject
    } = props;
    const [cancellationReason, setCancellationReason] = useState('customerRequestOption');
    const writeValue = useCallback(
        (value) => {
            setCancellationReason(value);
        },
        [setCancellationReason]
    );

    const handleCancelRenewal = useCallback(() => {
        onResolve(cancellationReason);
    }, [onResolve, cancellationReason]);

    const withDraw = useCallback(() => {
        withDrawRenewal();
        onReject();
    }, [withDrawRenewal, onReject]);

    const getWithDrawLink = useCallback(() => {
        return (
            <FormattedMessage
                {...gatewayMessages.startedByMistake}
                values={{
                    link: (
                        <Link onClick={() => withDraw()} to="/">
                            {translator(gatewayMessages.withdrawRenewalCtrlTransaction)}
                        </Link>
                    )
                }}
            />
        );
    }, [translator, withDraw]);
    const overrides = {
        '@field': {
            onValueChange: writeValue
        },
        reasonForCancel: {
            value: cancellationReason,
            availableValues: reasons
        },
        withdrawTransaction: {
            value: getWithDrawLink()
        }
    };

    return (
        <ModalNext isOpen={isOpen}>
            <ModalHeader title={title} />
            <ModalBody
                contentLayout={{
                    component: 'grid'
                }}
            >
                <MetadataContent uiProps={metadata.componentContent.content} overrideProps={overrides} />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onReject} type="outlined">
                    {cancelBtnLabel}
                </Button>
                <Button onClick={handleCancelRenewal}>
                    {actionBtnLabel}
                </Button>
            </ModalFooter>
        </ModalNext>
    );
}

CancellationReason.propTypes = {
    title: PropTypes.string.isRequired,
    actionBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string.isRequired,
    withDrawRenewal: PropTypes.func.isRequired,
    reasons: PropTypes.shape([]).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    onResolve: PropTypes.func.isRequired
};

export default CancellationReason;
